import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../store"


export interface TestSliceState {
    queryParamsParsed: boolean
    testplanId: number | null
    dataCollectionEventId: number | null
    stimlusBundleId: number | null
}
const initialState: TestSliceState = {
    queryParamsParsed: false,
    testplanId: null,
    dataCollectionEventId: null,
    stimlusBundleId: null,
}

export const testSlice = createSlice({
    name: "test",
    initialState,
    reducers: {
        setQueryParamsParsed: (state, action: PayloadAction<boolean>) => {
            state.queryParamsParsed = action.payload
        },
        setTestplanId: (state, action: PayloadAction<number | null>) => {
            state.testplanId = action.payload
        },
        setDataCollectionEventId: (state, action: PayloadAction<number | null>) => {
            state.dataCollectionEventId = action.payload
        },
        setStimulusBundleId: (state, action: PayloadAction<number | null>) => {
            state.stimlusBundleId = action.payload
        },
    }
})

export const { 
    setQueryParamsParsed,
    setTestplanId,
    setDataCollectionEventId,
    setStimulusBundleId,
} = testSlice.actions
export const selectQueryParamsParsed = (state: RootState) => state.test.queryParamsParsed
export const selectTestplanId = (state: RootState) => state.test.testplanId
export const selectDataCollectionEventId = (state: RootState) => state.test.dataCollectionEventId
export const selectStimulusBundleId = (state: RootState) => state.test.stimlusBundleId
export const testSliceReducer = testSlice.reducer