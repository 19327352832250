import {useState, useEffect} from 'react'
import {select as d3select} from "d3-selection"
import {arc as d3arc} from 'd3-shape'
import {easeLinear} from 'd3-ease'
import {interpolateNumber, piecewise} from 'd3-interpolate'
import {transition as d3transition} from 'd3-transition'


interface QuestionTimerProps {
    durationSeconds: number
    onTimerExpired: ()=>void
}
export function QuestionTimer(props: QuestionTimerProps){
    const [rendered, setRendered] = useState(false)

    useEffect(()=>{
        if (!rendered){
            const timeoutId = window.setTimeout(
                props.onTimerExpired, 
                // Add an extra half-second so that the timer reaches zero.
                // And because we write magnanimous software...
                props.durationSeconds * 1000 + 500,
            )
            const cleanupTimer = renderTimer(
                props.durationSeconds,
                "#question-timer",
                window.innerWidth <= 500
            )
            setRendered(true)
            return () => {
                // When the component unmounts remove the SVG element
                cleanupTimer()
                // And clear the timeout callback in case it didn't fire
                // which will happend whenever the user hits "submit"
                // without the timer expiring.
                clearTimeout(timeoutId)
            }
        }
    }, 
    // eslint-disable-next-line
    [])
    return (
        <div id="question-timer"></div>
    )
}


function renderTimer(
    durationSeconds: number, 
    targetSelector: string,
    smallScreen: boolean,
): ()=>void{
    let height = 120
    let width = 120
    if (smallScreen) {
        width = 60
        height = 60
    }
    /* 
        In order to resolve the .transition method of a selection we need to 
        import d3-transition, but if we only import it (without using it) the 
        build-time tree-shaker will remove it. So this line uses the d3-transition 
        package so that it doesn't get removed, which lets us resolve our 
        .transition calls later down.
    */
    d3transition()
    const svg = d3select(targetSelector)
        .append("svg")
        .attr("height", height)
        .attr("width", width)
    const drawG = svg.append("g")
        .attr("transform", `translate(${height / 2}, ${width / 2})`)
    const arcGen = d3arc()
    const arcPath = arcGen({
        innerRadius: height / 2 - 5,
        outerRadius: height / 2,
        startAngle: 0,
        endAngle: Math.PI * 2
    })
    const anulusG = drawG.append("g")
    // arc
    anulusG
        .append("path")
        .attr("d", arcPath)
        .attr("fill", "yellowgreen")
        .transition()
        .duration(durationSeconds * 1000)
        .ease(easeLinear)
        .attrTween("d", ()=>{
            const interpolator = interpolateNumber(0, Math.PI * 2)
            return (t: number) => {
                return arcGen({
                    innerRadius: height / 2 - 5,
                    outerRadius: height / 2,
                    startAngle: interpolator(t),
                    endAngle: Math.PI * 2,
                }) as string
            }
        })
        .attrTween("fill", ()=>{
            return piecewise(["yellowgreen", "yellow", "red"])
        })
    
    if (!smallScreen){
        const infoTextG = drawG
        .append("g")
        .attr("transform", `translate(0, 8)`)
        .attr("id", "question-timer-info-text")
        const infoText = infoTextG
            .append("text")
        infoText
            .append("tspan")
            .text("seconds")
            .attr("dy", "1.2em")
            .attr("x", 0)
            .attr("text-anchor", "middle")
        infoText
            .append("tspan")
            .text("remaining")
            .attr("dy", "1.2em")
            .attr("x", 0)
            .attr("text-anchor", "middle")
    }
    
    const secondsTranslate = smallScreen ? 11 : -5
    const secondsTextG = drawG
        .append("g")
        .attr("id", "question-timer-seconds-text")
    secondsTextG
        .append("text")
        .attr("text-anchor", "middle")
        .attr("transform", `translate(0, ${secondsTranslate})`)
        .text(durationSeconds.toString())
        .transition()
        .duration(durationSeconds * 1000)
        .ease(easeLinear)
        .textTween(()=>{
            const interpolate = interpolateNumber(durationSeconds, 0)
            return (t: number) => {
                return Math.ceil(interpolate(t)).toString()
            }
        })

    const cleanup = ()=>{
        svg.remove()
    }
    return cleanup
}

// export function QuestionTimer(props: QuestionTimerProps){
//     const [remaining, setRemaining] = useState(props.duration)
//     const [intervalId, setIntervalId] = useState<number|null>(null)

//     useEffect(()=>{
//         if (intervalId === null){
//             const id = window.setInterval(
//                 ()=>setRemaining(remaining => remaining - 1), 
//                 1000,
//             )
//             setIntervalId(id)
//         }
//     }, [intervalId, remaining])
//     if (remaining === 0){
//         if (intervalId !== null){
//             clearInterval(intervalId)
//             props.onTimerExpired()
//         }
//     }
//     return (
//         <div>{remaining}</div>
//     )
// }
