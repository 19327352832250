import React from 'react'
import './hcnav.css'

interface Props {
    name: string | undefined
    onLogout: () => void
}

export default function NavBar(props: Props){
    const barText = window.innerWidth > 500 ? 
        "Healthcare.AI User Lab" :
        "User Lab"
    return (
        <div id="navbar-container">
            <div id="navbar-icon-title">
                <div id="navbar-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="-5 -2.5 23 23" >
                        <style type="text/css">
                            {'.st0{fill:#FFFFFF;}'}
                        </style>
                        <path className="st0" d="M5.7,18.3c-0.6-0.3-1.1-0.7-1.5-1.2c-0.4-0.5-0.8-1.1-1-1.8c-0.2-0.7-0.4-1.4-0.4-2.2c0-4.6,3.8-7,3.4-11.6  c0-0.3,0-0.5-0.1-0.8C5.9,0.5,5.8,0.3,5.7,0.2c0.6,0.3,1.1,0.7,1.5,1.2c0.4,0.5,0.8,1.1,1,1.8c0.2,0.7,0.4,1.4,0.4,2.2  c0,3.9-3.6,6.8-3.6,11c0,0.4,0.1,0.8,0.2,1.1C5.3,17.8,5.4,18.1,5.7,18.3"/>
                        <path className="st0" d="M9.1,18.1c-0.4-0.2-0.7-0.5-1-0.8c-0.3-0.3-0.5-0.7-0.7-1.2c-0.2-0.4-0.2-0.9-0.2-1.5c0-3.1,2.8-4.8,2.4-8.7  c0.5,0.5,1,1.4,1.3,2C10.9,8.3,11,8.8,11,9.3c0,2.6-2.4,4.6-2.4,7.4c0,0.3,0,0.5,0.1,0.7C8.8,17.7,8.9,17.9,9.1,18.1"/>
                        <path className="st0" d="M2,12.9c-0.4-0.2-0.7-0.5-1-0.8c-0.3-0.3-0.5-0.7-0.7-1.2C0.2,10.4,0.1,10,0.1,9.4c0-3.1,2.8-4.8,2.4-8.7  c0.5,0.5,1,1.4,1.3,2C3.9,3.2,4,3.6,4,4.2c0,2.6-2.4,4.6-2.4,7.4c0,0.3,0,0.5,0.1,0.7C1.8,12.5,1.9,12.7,2,12.9"/>
                    </svg>
                </div>
                <div id="navbar-title">
                    {barText}
                </div>
            </div>
            <NameDropdown name={props.name} onLogout={props.onLogout} />
        </div>
    )
}

interface IState {
    active: boolean
}

class NameDropdown extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            active: false,
        };
    }

    OnActivate = ()=>{
        this.setState({active: true})
    }

    OnDeactivate = ()=>{
        this.setState({active: false})
    }

    render(){
        return(
            <div id="name" onMouseEnter={this.OnActivate} onMouseLeave={this.OnDeactivate}>
                {this.props.name || "Testing User"}
                {
                    this.state.active ?
                    <div id="logout-dropdown" onClick={this.props.onLogout}>
                        Sign Out
                    </div>
                    :
                    null
                }
                
            </div>
        )
    }
}