import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import { RootState } from '../../../store'
import { getAuthTokenFromAPI } from '../../../data/api-io'

export enum HASOrigin {
    keynote = "keynote",
    userFeedbackLab = "user-feedback-lab",
}
export interface HASInfoState {
    authToken: string | null
    origin: HASOrigin | null
    attendeeId: number | null
    authError: string | null
}
const initialState: HASInfoState = {
    authToken: null,
    origin: null,
    attendeeId: null,
    authError: null,
}

// Thunk for retrieving the HAS auth token
// see https://stackoverflow.com/questions/60316251/how-to-use-redux-thunk-with-redux-toolkits-createslice
export const getAuthToken  = createAsyncThunk(
    "HASUser/getAuthToken",
    async (userEmail: string, thunkAPI) => {
        const response = await getAuthTokenFromAPI(userEmail)
        return response.data
    }
)

export const HASInfoSlice = createSlice({
    name: 'HASUser',
    initialState,
    reducers: {
        clearAuthInfo: (state) => {
            state.authToken = null
            state.attendeeId = null
        },
        setOrigin: (state, action: PayloadAction<HASOrigin>) => {
            state.origin = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAuthToken.fulfilled, (state, action) => {
            state.authToken = action.payload.applicationAccessToken
            state.attendeeId = action.payload.attendeeId
        })
        builder.addCase(getAuthToken.rejected, (state, action) => {
            state.authError = action.error.message ? action.error.message : "Error obtaining HAS auth info."
        })
    }
})

export const {
    setOrigin,
} = HASInfoSlice.actions
export const selectHASOrigin = (state: RootState) => state.HASInfo.origin
export const selectHASAuthToken = (state: RootState) => state.HASInfo.authToken
export const selectHASAuthError = (state: RootState) => state.HASInfo.authError
export const HASInfoReducer = HASInfoSlice.reducer
