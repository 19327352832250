import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../store"

export interface UserState {
    email: string | null
}
const initialState: UserState = {
    email: null,
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string | null>) => {
            state.email = action.payload
        },
    }
})

export const userSliceReducer = userSlice.reducer
export const { setEmail } = userSlice.actions
export const selectUserEmail = (state: RootState) => state.user.email