import { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom'

import './carousel.css'
import * as api from '../../../data/api-types'
import {QuestionTimer} from './questionTimer'


const getIDontKnowOptionId = (question: api.QuestionRespondentFacing): number => {
    const matchingOptions = question.options.filter(o => o.index === 0)
    if (matchingOptions.length !== 1){
        throw new Error(`Question with id ${question.id} does not have an "I don't know option" with index -1.`)
    }
    return matchingOptions[0].id
}


export interface SingleQuestionResponse {
    questionId: number
    optionIds: number[]
    displayedTimestamp: Date
    answeredTimestamp: Date
    timerExpired: boolean
}

interface CarouselProps {
    questions: api.TestPlanFullQuestion[]
    onResponse: (res: SingleQuestionResponse) => void
    // called when all the questions have been answered
    done: () => void
}

interface CarouselContainerStyle {
    opacity: number
    transition: string
}

export function QuestionCarousel (props: CarouselProps) {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const currentTestPlanQuestion = props.questions.filter(tpq => tpq.index === currentQuestionIndex)[0]
    const currentQuestion = currentTestPlanQuestion.question
    const [displayedTime, setDisplayedTime] = useState(new Date())
    const [containerStyle, setContainerStyle] = useState<CarouselContainerStyle>({opacity: 1, transition: 'all 1s ease'});
    const [selections, setSelections] = useState([getIDontKnowOptionId(props.questions[0].question)])
    const selectionsRef = useRef(selections)
    const updateSelections = (newSelections: number[]) => {
        /* 
        We need to use a ref for selections because we only render our
        timer component once per question, at which point its callback
        captures a snapshot of 'selections' since that reference changes
        every time this component renders. So if we change selections
        after rendering our timer then when the timer callback goes off
        we have a stale reference to selections.
        By using a ref we can keep selectionsRef.current up to date
        and then close over that since react will guarantee that the
        reference to selectionsRef stays the same across renders.
        */
        setSelections(newSelections)
        selectionsRef.current = newSelections
    }

    const advanceToNextQuestion = ()=>{
        // We are fading out/in the question area when we transition between
        // questions. Otherwise we get jarring flicker, and it can be hard
        // to tell that the question changed.
        // Fade the question area out and then take action
        setContainerStyle({opacity: 0, transition: 'all .25s ease-in'});
        setTimeout(()=>{
            if (currentQuestionIndex === props.questions.length - 1){
                props.done()
            } else {
                const newQuestionIndex = currentQuestionIndex + 1 
                const iDontKnowOptionId = getIDontKnowOptionId(props.questions[newQuestionIndex].question)
                ReactDOM.unstable_batchedUpdates(() => {
    
                    // updateSelections([iDontKnowOptionId])
                    setCurrentQuestionIndex(newQuestionIndex)
                    setSelections([iDontKnowOptionId])
                    setDisplayedTime(new Date())
                    // Fade the question area back in
                    setContainerStyle({opacity: 1, transition: 'all .75s ease-out'})
                })
            }
        }, 250)
    }

    const submitResponse = (timerExpired: boolean) => {
        // Pass the response object up the component chain to be POSTed
        // to the API
        const singleQuestionResponse: SingleQuestionResponse = {
            questionId: currentQuestion.id,
            // optionIds: selectionsRef.current,
            optionIds: selections,
            displayedTimestamp: displayedTime,
            answeredTimestamp: new Date(),
            timerExpired,
        }
        props.onResponse(singleQuestionResponse)
        
    }

    // The question ends either when the user presses "submit"
    // or the timer expires. If the timer expires and the user
    // has selected one or more options, we submit those as their
    // answer. Otherwise, if they haven't selected anything we don't
    // even submit an answer.
    const onSubmitPressed = () => {
        submitResponse(false)
        advanceToNextQuestion()
    }
    const onTimerExpired = ()=>{
        console.log("onTimerExpired")
        if (selections.length > 0){
            submitResponse(true)
        }
        advanceToNextQuestion()
    }
    return (
        <div style={containerStyle} id="carousel-container">
            <div id="question-timer-container">
                <QuestionTimer 
                    durationSeconds={currentTestPlanQuestion.timer_seconds}
                    // durationSeconds={99}
                    onTimerExpired={onTimerExpired}
                    key={currentQuestion.id}
                />
            </div>
            <div id="carousel-progress-and-options">
                <div id="carousel-progress">
                    (Question {currentQuestionIndex + 1} of {props.questions.length})
                </div>
                <CarouselQuestion 
                    question={currentQuestion}
                    selections={selections}
                    setSelections={updateSelections}
                    onSubmit={onSubmitPressed}
                    key={currentQuestion.id}
                />
            </div>
        </div>
    )
}

interface CarouselQuestionProps {
    question: api.QuestionRespondentFacing
    selections: number[]
    setSelections: (selections: number[])=>void
    onSubmit: () => void
}
function CarouselQuestion(props: CarouselQuestionProps){
    const [displayedTime, setDisplayedTime] = useState<Date | null>(null)
    const [answered, setAnswered] = useState(false)

    // Only make the button active if the user has selected an option for
    // this question
    const buttonActive = props.selections.length > 0
    const buttonClass = `button-submit ${buttonActive ? "" : "inactive-button"}`
    const selectionInstructions = props.question.multi_select ?
        "Select all that apply."
        :
        "Select one."


    useEffect(()=>{
        if (displayedTime === null){
            setDisplayedTime(new Date())
        }
    }, [displayedTime])

    const onOptionClick = (id: number) => {
        if(props.question.multi_select){
            const iDontKnowOptionId = getIDontKnowOptionId(props.question)
            if (props.selections.includes(id)) {
                // They clicked on an option that was already selected.
                // Deselect it by removing its id from the selections
                let newSelections = props.selections.filter(s => s!==id)
                // If there are no selections then re-select "I don't know"
                if (newSelections.length === 0){
                   newSelections = [iDontKnowOptionId] 
                }
                props.setSelections(newSelections)
            } else {
                // If the selected option is "I don't know" then remove all other selections
                // since that one makes all the others moot
                if (id === iDontKnowOptionId){
                    props.setSelections([iDontKnowOptionId])
                }
                else {
                    // Otherwise add the id to the selections and make sure the "I don't know"
                    // option ID is not part of the selections now that something else
                    // has been selected
                    let newSelections = props.selections.concat(id).filter(oid => oid !== iDontKnowOptionId)
                    props.setSelections(newSelections)
                }
            }
        } else {
            if (props.selections.includes(id)) {
                // Do nothing, it was already selected
            } else {
                // Make this the only selection
                props.setSelections([id])
            }
        }
    }

    const onSubmit = () => {
        if (!buttonActive || answered){
            return
        }
        setAnswered(true)
        // Submit response
        if (displayedTime !== null){
            props.onSubmit()
        }
    }

    return (
        <div id="carousel-question-container">
            <div id="carousel-question-text">{props.question.text} ({selectionInstructions})</div>
        {
            props.question.options
            .sort((a, b)=>{return a.index - b.index})
            .map(o => {
                return <CarouselQuestionOption 
                    id={o.id}
                    text={o.text}
                    selected={props.selections.includes(o.id)}
                    onClick={onOptionClick}
                    key={o.id}
                />
            })
        }
            <div 
                className={buttonClass}
                style={{marginTop: "15px"}}
                onClick={onSubmit}
            >
            Submit
        </div>
        </div>
    )
}

interface CarouselQuestionOptionProps {
    id: number
    text: string
    selected: boolean
    onClick: (id: number)=>void
}
function CarouselQuestionOption(props: CarouselQuestionOptionProps) {
    const className = `carousel-question-option ${props.selected ? "selected" : ""}`
    return (
        <div 
            className={className}
            onClick={()=>{props.onClick(props.id)}}
        >
            {props.text}
        </div>
    )
}