import { useState, useEffect } from 'react'

import { Grid } from "react-loader-spinner";
import {useHistory} from 'react-router-dom'

import './userPage.css'
import * as api from '../../../data/api-types'
import NavBar from '../hcnav/hcnav'
import { StimulusBundle } from '../stimulus-bundle/stimulus-bundle'
import { SingleQuestionResponse } from '../question-carousel/carousel';
import { awardHASStamp, getTestPlanRespondentFacing, submitResponse } from '../../../data/api-io'
import keynoteStamp from './icons/analytics_outlook.svg'
import feedbackStamp from './icons/user_feedback_lab.svg'
import { selectTestplanId, selectStimulusBundleId, selectDataCollectionEventId } from './testSlice'
import { useSelector } from 'react-redux';
import { HASOrigin, selectHASAuthError, selectHASAuthToken, selectHASOrigin } from '../login/HASInfoSlice'


interface UserPageProps {
    userEmail: string
    onLogout: () => void
}

const recaptchaToken = "dummy"

export default function UserPage(props: UserPageProps) {
    const testplanId = useSelector(selectTestplanId)
    const dataCollectionEventId = useSelector(selectDataCollectionEventId)
    const stimulusBundleId = useSelector(selectStimulusBundleId)
    const origin = useSelector(selectHASOrigin)
    const [spinner, setSpinner] = useState(true)
    const [bundleIndex, setBundleIndex] = useState(0)
    const [activeTest, setActiveTest] = useState<api.TestPlanRespondentFacing | null>(null)
    const [splitModeActive, setSplitModeActive] = useState(false)
    /* 
    Keep a separate list of stimulus bundles that we will iterate through since we may be operating
    in "split" mode where we will only show one of the stimulus bundles in the test plan.
    This array will already be sorted by index by the time it is set.
    */
    const [stimulusBundles, setStimulusBundles] = useState<api.TestPlanFullStimulusBundle[] | null>(null)
    // Whether to display the final page in split mode (instead of the results page)
    const [bundleCompletionPageVisible, setBundleCompletionPageVisible] = useState(false)
    const history = useHistory()

    // Get the current bundle
    let currentBundle: api.TestPlanFullStimulusBundle | undefined;
    if (stimulusBundles) {
        currentBundle = stimulusBundles[bundleIndex]
    }

    useEffect(() => {
        // // Skip straight to the mock results page (for dev use)
        // this.spinnerOff()
        // this.setState({
        //     aggregateResults: exampleAggregateResults,
        //     individualResults: exampleIndividualResultsPaired,
        // })
        const onTestPlanReceived = (plan: api.TestPlanRespondentFacing) => {
            setActiveTest(plan)
            if (stimulusBundleId !== null){
                // We are in split mode where we will only display one stimulus bundle
                setSplitModeActive(true)
                const targetBundle = plan.stimulus_bundles.filter(sb => sb.stimulus_bundle.id === stimulusBundleId)
                if (targetBundle.length !== 1){
                    console.error(`TestPlan with id ${plan.id} has no stimulus bundle with id ${stimulusBundleId}`)
                    return
                }
                setStimulusBundles(targetBundle)
            } else {
                setStimulusBundles(plan.stimulus_bundles)
            }
            setSpinner(false)
        }
        
        if (activeTest === null){
             /* Check if a testplan was provided in the URL */
            if (testplanId !== null) {
                // Fetch that testplan
                getTestPlanRespondentFacing(recaptchaToken, testplanId)
                .then(res => {
                    onTestPlanReceived(res.data)
                })
                .catch(e => {setSpinner(false); console.log(e)})
            } 
            /* This shouldn't happen now that we require a testplan in the url */
            else {
                throw new Error("We should always have a testplan in the url.")
            }
        }
       
        // Check if the bundle index exceeds the length of the bundle array indicating
        // that we have displayed all the bundles and it is time to show the results
        if (activeTest && stimulusBundles && bundleIndex === stimulusBundles.length && !bundleCompletionPageVisible) {
        // Skip straight to results
        // if (activeTest && demoResults == null){
            if (splitModeActive){
                // If we are in split mode just show the split mode conclusion page
                setBundleCompletionPageVisible(true)
            } else {
                // Otherwise if the testplan is configured to display results, navigate to the demo result route
                if (activeTest.display_results){
                    history.push(`/results/${activeTest.id}`)
                } else {
                    setBundleCompletionPageVisible(true)
                }
            }
        } 
    }, [
        activeTest,
        bundleIndex,
        bundleCompletionPageVisible,
        splitModeActive,
        history,
        stimulusBundles,
        testplanId,
        stimulusBundleId,
        ]
    )

    const onResponse = async (response: SingleQuestionResponse) => {
        if (activeTest && currentBundle){
            try {
                await submitResponse(
                    recaptchaToken,
                    props.userEmail,
                    activeTest.id,
                    dataCollectionEventId,
                    currentBundle.stimulus_bundle.stimulus_id,
                    response.questionId,
                    response.displayedTimestamp,
                    response.answeredTimestamp,
                    response.optionIds,
                    response.timerExpired
                )
            } catch (e) {
                console.error(e)
            }
        }
    }

    const onBundleFinished = () => {
        setBundleIndex(bundleIndex + 1)
    }

    return (
        <div>
            {
                origin === HASOrigin.keynote ?
                null
                :
                <NavBar name={props.userEmail} onLogout={props.onLogout} />

            }
            {spinner ? 
            <div className="spinner-container">
                <Grid color="#00BFFF" height={80} width={80}/>
            </div>
                : 
                null 
            }
            {
                activeTest && currentBundle && bundleIndex < activeTest.stimulus_bundles.length && !bundleCompletionPageVisible ?
                <StimulusBundle
                    userEmail={props.userEmail}
                    stimulusBundle={currentBundle.stimulus_bundle}
                    questions={activeTest.questions}
                    onResponse={onResponse}
                    onBundleFinished={onBundleFinished}
                    key={currentBundle.stimulus_bundle.id}
                />
                :
                null
            }
            {
                bundleCompletionPageVisible ? 
                <CompletionPage/>
                :
                null
            }
            
        </div>
    )
}

interface CompletionPageProps {}
function CompletionPage(props: CompletionPageProps){
    // When rendering check for the presence of an "origin"
    // url param indicating that the user came from the HAS
    // testing lab, in which case we need to display the
    // Healthcare.AI stamp.
    // Parse the URL params
    const origin = useSelector(selectHASOrigin)
    const authToken = useSelector(selectHASAuthToken)
    const authError = useSelector(selectHASAuthError)
    let showStamp = false
    let stampImage = keynoteStamp
    let alt = "image of Healthcare.AI analytics-outlook stamp"
    switch (origin) {
        case HASOrigin.userFeedbackLab:
            showStamp = true
            stampImage = feedbackStamp
            alt = "image of user-feedback stamp"
            
            break
        case HASOrigin.keynote:
            showStamp = true
            stampImage = keynoteStamp
            alt = "image of Healthcare.AI analytics-outlook stamp"
            break
    }

    useEffect(()=>{
        // Award the stamp if they came from HAS
        if (origin !== null){
            const stampId = origin === HASOrigin.userFeedbackLab ? 39 : 40
            if (authToken !== null){
                awardHASStamp(authToken, stampId)
            } else {
                console.error("Auth error:", authError)
            }
        }
    // eslint-disable-next-line
    }, [])

    return (
        <div id="split-mode-conclusion">
        {
            showStamp ?
            <div id="stamp-container">
                <img id="stamp-image" src={stampImage} alt={alt}/>
                <h2>
                    Thank you for your feedback! Your response has been submitted, 
                    and you've earned a stamp in the HAS game.
                </h2>
            </div>
            :
            <h1>Thank you!</h1>
        }
        </div>
    )
    
}