import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../store"


export interface AdminSliceState {
    userlabAccessToken: string | null
    graphAccessToken: string | null
}
const initialState: AdminSliceState = {
    userlabAccessToken: null,
    graphAccessToken: null,
}

export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setUserlabAccessToken: (state, action: PayloadAction<string | null>) => {
            state.userlabAccessToken = action.payload
            console.log("UserLab access token", action.payload)
        },
        setGraphAccessToken: (state, action: PayloadAction<string | null>) => {
            state.graphAccessToken = action.payload
            console.log("Graph access token", action.payload)
        },
    }
})

export const { setUserlabAccessToken, setGraphAccessToken } = adminSlice.actions
export const selectUserlabAccessToken = (state: RootState) => state.admin.userlabAccessToken
export const selectGraphAccessToken = (state: RootState) => state.admin.graphAccessToken
export const adminSliceReducer = adminSlice.reducer