import './tile.css'

interface TileProps {
    children: JSX.Element
}

export default function Tile(props: TileProps){
    return (
        <div className="tile">
            {props.children}
        </div>
    )
}