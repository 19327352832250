import './stimulus-bundle.css'
import Tile from '../tile/tile'

import { VegaLite } from 'react-vega';
import { StimulusBundleFullResponse, StimulusFullResponse, TestPlanFullQuestion } from '../../../data/api-types';
import { QuestionCarousel, SingleQuestionResponse } from '../question-carousel/carousel'
import { useEffect, useState } from 'react';


interface StimulusBundleProps {
    userEmail: string
    stimulusBundle: StimulusBundleFullResponse
    questions: TestPlanFullQuestion[]
    onResponse: (response: SingleQuestionResponse) => void
    onBundleFinished: () => void
}

export function StimulusBundle(props: StimulusBundleProps){
    const [staticPageIndex, setStaticPageIndex] = useState(0)
    const [stimulusActive, setStimulusActive] = useState(props.stimulusBundle.staticpages.length === 0)
    const [staticPageKey, setStaticPageKey] = useState(0)

    const onNextStaticPage = () => {
        if (props.stimulusBundle.staticpages.length === 0 || staticPageIndex === props.stimulusBundle.staticpages.length - 1){
            // we are at the end of the static pages
            setStimulusActive(true)
        } else {
            setStaticPageIndex(staticPageIndex + 1)
            setStaticPageKey(Math.random())
        }
    }

    const onStimulusFinished = () => {
        props.onBundleFinished()
    }

    return (
        <div id="stimulus-bundle-container">
        {
            stimulusActive ? 
            <div id="question-container">
                <Tile>
                    <div id="stimulus-container" className="flex-center">
                        <Stimulus stimulus={props.stimulusBundle.stimulus}/>
                    </div>
                </Tile>
                <QuestionCarousel
                    questions={props.questions}
                    onResponse={props.onResponse}
                    done={onStimulusFinished}
                />
            </div>
            :
            <StaticPage 
                image={props.stimulusBundle.staticpages[staticPageIndex].staticpage.image}
                timerSeconds={props.stimulusBundle.staticpages[staticPageIndex].timer_seconds}
                onNext={onNextStaticPage}
                key={staticPageKey}
            />
        }
        </div>
    )
}

interface StaticPageProps {
    image: string
    timerSeconds: number
    onNext: ()=>void
}
function StaticPage(props: StaticPageProps) {
    const [intervalId, setIntervalId] = useState<number | null>(null)
    const [timeRemaining, setTimeRemaining] = useState(props.timerSeconds)

    useEffect(()=>{
        if (intervalId === null){
            const intervalId: number = window.setInterval(
                () => setTimeRemaining(time => time - 1),
                1000
            )
            setIntervalId(intervalId)
        }
        if (timeRemaining === 0 && intervalId !== null){
            clearInterval(intervalId)
        }
    }, [intervalId, timeRemaining])

    const onButtonClick = () => {
        if (!nextAvailable){
            return
        } else {
            props.onNext()
        }
    }

    const nextAvailable = timeRemaining === 0
    const buttonClass = `button-next ${nextAvailable ? "" : "inactive-button"}`
    let buttonText = "Next"
    if (!nextAvailable){
        buttonText = `Next available in ${timeRemaining}s`
    }
    return (
        <div id="static-page-container">
            <img 
                src={`data:image/png;base64,${props.image}`}
                alt="Explanatory page."
            />
            <div 
                id="button-static-continue"
                className={buttonClass}
                onClick={onButtonClick}
            >
                {buttonText}
            </div>
        </div>
    )
}

interface StimulusProps {
    stimulus: StimulusFullResponse
}
export function Stimulus(props: StimulusProps){
        let out = null
        if (props.stimulus.static_chart){
            out = (
                <img 
                    alt={props.stimulus.description}
                    src={`data:image/png;base64,${props.stimulus.static_chart.image}`}
                />
            )
        } else if (props.stimulus.chartspec &&  props.stimulus.dataset) {
            // Make sure the width of the image is no larger than the screen
            const spec = JSON.parse(props.stimulus.chartspec.spec)
            if (spec.config.view.continuousWidth > (.95 * window.innerWidth)){
                spec.width = .85 * window.innerWidth
                console.log(spec.width)

            }
            
            out = (
                <VegaLite 
                    spec={spec} 
                    data={JSON.parse(props.stimulus.dataset.datajson)} 
                    actions={false}
                />
            )
        } else {
            console.error("Got an invalid stimulus, all three values were null.")
        }
        return out
}
