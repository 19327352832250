import { VegaLite } from 'react-vega';

import './stimulusBundleDetail.css'
import * as api from '../../../../data/api-types'
import * as pills from '../../pills/pills'


interface StimulusBundleDetailsProps {
    stimulusBundles: api.TestPlanFullStimulusBundle[]
}
export function StimulusBundleDetails(props: StimulusBundleDetailsProps){

    return (
        <div id="stimulus-bundle-detail">
        {
            props.stimulusBundles.map(sb => {
                return (
                    <SingleBundle 
                    stimulusBundle={sb} 
                    key={sb.stimulus_bundle.id}
                    />
                )
            })
        }
        </div>
    )
}

interface SingleBundleProps {
    stimulusBundle: api.TestPlanFullStimulusBundle
}
function SingleBundle(props: SingleBundleProps){
    return (
        <div className="stimulus-bundle-detail-single-bundle">
            <div className="stimulus-bundle-detail-single-header">
                <h1>{props.stimulusBundle.stimulus_bundle.stimulus.name}</h1>
                <pills.PillID 
                    text={props.stimulusBundle.stimulus_bundle.id.toString()}
                />
                <p>{props.stimulusBundle.stimulus_bundle.stimulus.description}</p>
            </div>
            <div className="stimulus-bundle-detail-image-container">
                <div className="stimulus-bundle-detail-static-pages-container">
                    <h3>Static Pages</h3>
                    {
                        props.stimulusBundle.stimulus_bundle.staticpages.length === 0 ?
                        <StaticPagePlaceholder />
                        :
                        props.stimulusBundle.stimulus_bundle.staticpages.map(sp => {
                            return <StaticPageTile staticPage={sp} key={sp.staticpage.id}/>
                        })
                    }
                </div>
                <div className="stimulus-bundle-detail-stimulus-container">
                    <h3>Stimulus</h3>
                    <StimulusTile stimulus={props.stimulusBundle.stimulus_bundle.stimulus}/>
                </div>
            </div>
        </div>
    )
}

function StaticPagePlaceholder(props: {}){
    return (
        <div className="stimulus-bundle-detail-static-page-placeholder sbd-tile">
            <div className="tag-placeholder"></div>
            <div className="image-none">None</div>
        </div>
    )
}

interface StaticPageTileProps {
    staticPage: api.StimulusBundleFullStaticPage
}
function StaticPageTile (props: StaticPageTileProps){
    const timerText = `${props.staticPage.timer_seconds}s`
    return (
        <div className="stimulus-bundle-detail-static-page-tile sbd-tile">
            <pills.PillMinTime text={timerText} />
            <ImageFrame image={
                <img 
                    alt={props.staticPage.staticpage.description}
                    src={`data:image/png;base64,${props.staticPage.staticpage.image}`}
                />
            } />
        </div>
    )
}

interface StimulusTileProps {
    stimulus: api.StimulusFullResponse
}
function StimulusTile(props: StimulusTileProps){
    return (
        <div className="stimulus-bundle-detail-stimulus-tile sbd-tile">
            {
                props.stimulus.type === api.StimulusType.Static ?
                <pills.PillTypeStatic />
                :
                <pills.PillTypeInteractive />
            }
            <ImageFrame image={
                props.stimulus.type === api.StimulusType.Static ?
                <img 
                    alt={props.stimulus.static_chart!.description}
                    src={`data:image/png;base64,${props.stimulus.static_chart!.image}`}
                />
                :
                <VegaLite 
                    spec={JSON.parse(props.stimulus.chartspec!.spec)} 
                    data={JSON.parse(props.stimulus.dataset!.datajson)} 
                />
            }/>
        </div>
    )
}

interface ImageFrameProps {
    image: JSX.Element
}
function ImageFrame(props: ImageFrameProps){
    /*
    This element ensures the correct padding and background color for stimulus-
    bundle images. 
    */
    return <div className="stimulus-bundle-detail-image-frame">{props.image}</div>
}
