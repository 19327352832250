import {useEffect, useState} from 'react'
import { Grid } from "react-loader-spinner";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router'

import "./demoResults.css"
import IncorrectIcon from "./icons/incorrect.png"
import CorrectIcon from "./icons/correct.png"
import * as api from '../../../data/api-types'
import {getDemoResults} from '../../../data/api-io'
import render from './chart'
import {
    selectTestplanId,
    selectDataCollectionEventId,
    selectQueryParamsParsed,
} from '../userPage/testSlice'


const recaptchaToken = "dummy"

interface DemoResultProps {}
export default function DemoResultView(props: DemoResultProps){
    const [spinner, setSpinner] = useState(true)
    const [demoResults, setDemoResults] = useState<api.DemoResult | null>(null)
    const testplanId = useSelector(selectTestplanId)
    const dataCollectionEventId = useSelector(selectDataCollectionEventId)
    const queryParamsParsed = useSelector(selectQueryParamsParsed)
    const history = useHistory()

    useEffect(() => {
        /* 
            If the URL does not contain a testplanId and and eventId
            then we do not have enough information to submit responses
            and the user will need to obtain a complete URL.
        */
        if (queryParamsParsed && (dataCollectionEventId === null || testplanId === null)) {
            history.push("/missing-url")
        }
    }, [queryParamsParsed, dataCollectionEventId, testplanId, history])

    useEffect(() => {
        if (!demoResults){
            if (testplanId !== null && dataCollectionEventId !== null){
                getDemoResults(recaptchaToken, testplanId, dataCollectionEventId)
                    .then(resp => {
                        setDemoResults(resp.data)
                        setSpinner(false)
                    })
                    .catch(e => {
                        setSpinner(false);
                        console.log(e); 
                        console.log(e.message);
                    })
            }
        }
        
    }, [demoResults, testplanId, dataCollectionEventId])

    return (
        <div id="demo-result-container">
            {spinner ? 
            <div className="spinner-container">
                <Grid color="#00BFFF" height={80} width={80}/>
            </div>
                : 
                null 
            }
            {
                demoResults ?
                <DemoResultContent result={demoResults}/>
                :
                null
            }
        </div>
    )
}


interface DemoResultContentProps {
    result: api.DemoResult
}
function DemoResultContent(props: DemoResultContentProps){
    
    return (
        <div id="demo-result-view-container">
            <h1 id="result-title">{props.result.title}</h1>
            <div id="event-name-and-metrics-container">
                <EventNameAndMetrics result={props.result}/>
            </div>
            {
                props.result.question_results.map(qr => {
                    return <QuestionResult question={qr} key={qr.id}/>
                })
            }
        </div>
    )
}



interface EventNameAndMetricsProps {
    result: api.DemoResult
}
function EventNameAndMetrics(props: EventNameAndMetricsProps) {
    const plainProportionInt = (props.result.info_all_correct.plain_proportion * 100).toFixed(2)
    const healthcareAIProportionInt = (props.result.info_all_correct.healthcare_ai_proportion * 100 ).toFixed(2)
    const respondents = props.result.unique_respondents
    const plainProportion = respondents === 0 ? "NA" : plainProportionInt.toString() + "%"
    const healthcareAIProportion = respondents === 0 ? "NA" : healthcareAIProportionInt.toString() + "%"
    const hcaiProportionClass = respondents === 0 ? "" : "active"
    let pValue = "NA"
    let pValueClass = ""
    if (props.result.info_all_correct.p_value !== null){
        const formattedPValue = formatPValue(props.result.info_all_correct.p_value, 5)
        pValue = formattedPValue.pValue
        if (formattedPValue.significant) {
            pValueClass = " significant"
        }
    }
    return (
        <div>
            <div className="event-metrics-section">
                <h3 className="event-metrics-header">Event</h3>
                <h3 className="event-metrics-value">{props.result.data_collection_event_name}</h3>
            </div>
            <div className="event-metrics-section">
                <h3 className="event-metrics-header">Unique respondents</h3>
                <h3 className="event-metrics-value">{props.result.unique_respondents}</h3>
            </div>
            <div className="event-metrics-section">
                <h3 className="event-metrics-header">Respondents with the correct interpretation for all answers</h3>
                <div id="all-correct-container">
                    <div className="all-correct-item">
                        <h3 className="event-metrics-value">Plain chart</h3>
                        <h2 className="all-correct-value">{plainProportion}</h2>
                    </div>
                    <div className="all-correct-item">
                        <h3 className="event-metrics-value">Healthcare.AI SPC</h3>
                        <h2 
                            className={`all-correct-value ${hcaiProportionClass}`} 
                            id="healthcareai-all-correct-proportion"
                        >
                            {healthcareAIProportion}
                        </h2>
                    </div>
                    <div className="all-correct-item">
                        <h3 className="event-metrics-value">P-value</h3>
                        <h2 className={`all-correct-value ${pValueClass}`} id="pvalue-all-correct">{pValue}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface ResultTableWidths {
    option: number
    correct: number
    plainChart: number
    pValue: number
    enhancedChart: number
    rightPadding: number
}
interface QuestionResultProps {
    question: api.DemoResultQuestion
}
function QuestionResult(props: QuestionResultProps) {
    const [chartsRendered, setChartsRendered] = useState(false)

    const plainTarget = `plain-${props.question.id}`
    const enhancedTarget = `enhanced-${props.question.id}`

    const widths: ResultTableWidths = {
        option: 110,
        correct: 130,
        plainChart: 445,
        pValue: 150,
        enhancedChart: 435,
        rightPadding: 40,
    }
    const rowHeight = 50

    useEffect(()=>{
        if (!chartsRendered){
            const plainData = props.question.option_info.map(o => {
                return {
                    index: o.index,
                    proportion: o.plain_proportion,
                }
            })
            const enhancedData = props.question.option_info.map(o => {
                return {
                    index: o.index,
                    proportion: o.healthcare_ai_proportion,
                }
            })
            render({
                targetSelector: `#${plainTarget}`,
                chartWidth: 475,
                barHeight: 20,
                lineHeight: rowHeight,
                startPadding: 12,
                barColor: "#B2B2B2",
                orientLeft: true,
                data: plainData,
            })
            render({
                targetSelector: `#${enhancedTarget}`,
                chartWidth: 475,
                barHeight: 20,
                lineHeight: rowHeight,
                startPadding: 12,
                barColor: "#00AEFF",
                orientLeft: false,
                data: enhancedData,
            })
            setChartsRendered(true)
        }
    }, [chartsRendered, plainTarget, enhancedTarget, props.question.option_info])

    return (
        <div className="question-result-container">
            <h2 className="question-result-title">Q: {props.question.text}</h2>
            <div className="question-result-table-container">
                <div className="question-result-table-header-container">
                    <span className="question-result-header option center" style={{width: `${widths.option}px`}}>Option</span>
                    <span className="question-result-header correct center" style={{width: `${widths.correct}px`}}>Correct</span>
                    <span className="question-result-header plain left" style={{width: `${widths.plainChart}px`}}>Responses - Plain Line Chart  (N={props.question.plain_respondents})</span>
                    <span className="question-result-header p-value center" style={{width: `${widths.pValue}px`}}>P-value</span>
                    <span className="question-result-header enhanced left" style={{width: `${widths.enhancedChart}px`}}>Responses - Healthcare.AI SPC  (N={props.question.healthcare_ai_respondents})</span>
                </div>
                {
                    props.question.option_info.map((oi, i) => {
                        return (
                            <QuestionResultTableRow
                                option={oi}
                                darkBackground={i%2 === 0}
                                widths={widths}
                                height={rowHeight}
                                key={oi.id}
                            />
                        )
                    })
                }
                <div className="chart-target plain" id={plainTarget}></div>
                <div className="chart-target enhanced" id={enhancedTarget}></div>
            </div>
        </div>
    )
}


interface QuestionResultTableRowProps{
    option: api.DemoResultOptionInfo
    darkBackground: boolean
    widths: ResultTableWidths
    height: number
}
function QuestionResultTableRow(props: QuestionResultTableRowProps){
    let pValue = "NA"
    let pValueClass = "result-row-cell row-p-value"
    if (props.option.p_value !== null){
        const pValueNum = props.option.p_value
        const formattedPValue = formatPValue(pValueNum, 5)
        if (formattedPValue.significant) {
            pValueClass += " significant"
        }
        pValue = formattedPValue.pValue
    }

    let optionText = props.option.text
    if (optionText.length > 12) {
        optionText = optionText.slice(0, 12) + "..."
    }

    const icon = props.option.correct ? CorrectIcon : IncorrectIcon
    const imageAlt = props.option.correct ? "Correct option." : "Incorrect option."

    const containerClass = `question-result-table-row-container ${props.darkBackground ? "dark-row" : ""}`
    const fullWidth = props.widths.option + props.widths.correct + props.widths.plainChart + props.widths.pValue  + props.widths.enhancedChart + props.widths.rightPadding
    return (
        <div 
            className={containerClass}
            style={{
                height: `${props.height}px`,
                width: `${fullWidth}px`,
            }}
        >
            <div 
                className="result-row-cell row-option"
                style={{
                    width: `${props.widths.option}px`
                }}
            >
                {optionText}
            </div>
            <div 
                className="result-row-cell row-correct"
                style={{width: `${props.widths.correct}px`}}
            >
                <img src={icon} alt={imageAlt}/>
            </div>
            <div 
                className="result-row-cell row-plain-chart"
                style={{width: `${props.widths.plainChart}px`}}
            >
            </div>
            <div 
                className={pValueClass}
                style={{width: `${props.widths.pValue}px`}}
            >
                {pValue}
            </div>
            <div 
                className="result-row-cell row-enhanced-chart"
                style={{width: `${props.widths.enhancedChart}px`}}
            >
            </div>
        </div>
    )
}

interface formattedPValue {
    pValue: string
    significant: boolean
}

function formatPValue(pValue: number, places: number): formattedPValue {
    let pValueStr = pValue.toFixed(places)
    if (pValueStr === "0.00000"){
        pValueStr = "< .00001"
    }
    return {
        pValue: pValueStr,
        significant: pValue < 0.05,
    }
}