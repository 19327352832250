import {useState, ChangeEvent, KeyboardEvent, useEffect} from 'react';
import { Switch, Route, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import "./login.css"
import Tile from '../tile/tile'
import prodIcon from './icon/healthcareAI@3x.png'
import hcLogo from './icon/HCLogo.png'
import {
  selectTestplanId,
  selectDataCollectionEventId,
  selectQueryParamsParsed,
} from '../userPage/testSlice'


// See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))+$/

interface LoginProps {
    onLogin: (userEmail: string) => void
}

export default function Login(props: LoginProps){
    const [email, setEmail] = useState("")
    const [emailValid, setEmailValid] = useState(false)
    const url = useRouteMatch()
    const eventId = useSelector(selectDataCollectionEventId)
    const testplanId = useSelector(selectTestplanId)
    const queryParamsParsed = useSelector(selectQueryParamsParsed)
    const history = useHistory()

    useEffect(() => {
        /* 
            If the URL does not contain a testplanId and and eventId
            then we do not have enough information to submit responses
            and the user will need to obtain a complete URL.
        */
        if (queryParamsParsed && (eventId === null || testplanId === null)) {
            history.push("/missing-url")
        }
    }, [queryParamsParsed, eventId, testplanId, history])

      
    const buttonClass = `login-button ${emailValid? "active" : ""}`

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value
        setEmail(newEmail)
        if (emailPattern.test(newEmail)) {
            if (!emailValid){
                setEmailValid(true)
            }
        } else {
            if (emailValid){
                setEmailValid(false)
            }
        }
    }

    const onGetStartedClick = () => {
        if (emailValid) {
            props.onLogin(email)
        }
    }

    const onKeyUp = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            onGetStartedClick()
        }
    }

    return (
        <div id="login-container">
            <img id="hc-logo" src={hcLogo} alt="product icon"/>
            <Switch>
            <Route path={url.path}>
            <div id="small-screen-message">
                <h3>
                    We're sorry, this experience is designed for larger screens. 
                    Please use a laptop or a larger tablet. If you are already
                    using a tablet try rotating it so that the longer 
                    dimension runs horizontally.
                </h3>
            </div>
            
            <div id="login-tile-container">
            <Tile>
                <div id="login-tile">
                    <img id="hcai-icon" src={prodIcon} alt="product icon"/>
                    <input 
                        autoFocus
                        type="email"
                        placeholder="improvement@healthcare.com"
                        value={email}
                        onChange={onInputChange}
                        onKeyUp={onKeyUp}
                    />
                    <p>To get started please enter your email address.</p>
                    <div 
                        id="login-button"
                        className={buttonClass}
                        onClick={onGetStartedClick}
                    >
                        Get Started
                    </div>
                </div>
            </Tile>
            </div>
            </Route>
            </Switch>
        </div>
    )

    // return (
    //     <Switch>
    //     <Route path={`/missing-url`}>
    //       <div>
    //         <h1>Please ask your test administrator for the test URL.</h1>
    //       </div>
    //     </Route>
    //     <Route path={url.path}>
    //     <div id="login-container">
    //         <img id="hc-logo" src={hcLogo} alt="product icon"/>
    //         <div style={{width: "500px"}}>
    //         <Tile>
    //             <div id="login-tile">
    //                 <img id="hcai-icon" src={prodIcon} alt="product icon"/>
    //                 <input 
    //                     type="email"
    //                     placeholder="HAS registration email"
    //                     value={email}
    //                     onChange={onInputChange}
    //                     onKeyUp={onKeyUp}
    //                 />
    //                 <p>To get started please enter the email address that you used to register for HAS 2021.</p>
    //                 <div 
    //                     id="login-button"
    //                     className={buttonClass}
    //                     onClick={onGetStartedClick}
    //                 >
    //                     Get Started
    //                 </div>
    //             </div>
    //         </Tile>
    //         </div>
    //     </div>
    //     </Route>
    //     </Switch>
    // )
}