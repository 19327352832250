import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux'
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";


import { store } from './store'
import './index.css';
import App from './App';
import { msalConfig } from './components/admin/main/authConfig';

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store} >
    <MsalProvider instance={pca} >
    <Router>
      <App />
    </Router>
    </MsalProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

