import { HASOrigin } from "./components/user/login/HASInfoSlice";


export interface QueryParams {
    testplanId: number | null
    stimulusBundleId: number | null
    origin: HASOrigin | null
    email: string | null
    dataCollectionEvent: number | null
}

export function parseQueryParams(): QueryParams {
    const queryParams = new URLSearchParams(document.location.search)

    let testplanIdRaw = queryParams.get("testplan")
    let testplanId: number | null = null
    if (testplanIdRaw !== null){
        try {
            testplanId = parseInt(testplanIdRaw)
        } catch (error) {
            console.error(error)
        }
    }
    let stimulusBundleIdRaw = queryParams.get("stimulusBundle")
    let stimulusBundleId: number | null = null
    if (stimulusBundleIdRaw !== null){
        try {
            stimulusBundleId = parseInt(stimulusBundleIdRaw)
        } catch (error) {
            console.error(error)
        }
    }
    const originRaw = queryParams.get("origin")
    let origin: HASOrigin | null = null
    if (originRaw !== null){
        switch (originRaw){
            case HASOrigin.keynote:
                origin = HASOrigin.keynote
                break
            case HASOrigin.userFeedbackLab:
                origin = HASOrigin.userFeedbackLab
                break
            default:
                console.error("Invalid value for 'origin'.")
        }
    }
    const dataCollectionEventRaw = queryParams.get("event")
    let dataCollectionEvent: number | null = null
    if (dataCollectionEventRaw !== null){
        try {
            dataCollectionEvent = parseInt(dataCollectionEventRaw)
        } catch (error) {
            console.error(error)
        }
    }
    const out: QueryParams = {
        testplanId,
        stimulusBundleId,
        origin,
        email: queryParams.get("email"),
        dataCollectionEvent,
    }
    return out
}