/*HAS API Types*/
export interface HASAuthenticateResponse {
  attendeeId: number;
  adminLevel: number;
  applicationAccessToken: string;
}
export interface AdministratorCreate {
  email: string;
}
export interface AdministratorFull {
  email: string;
  id: number;
}
export interface AdministratorDelete {
  id: number;
}
export interface ChartSpec {
  name: string;
  description: string;
  spec: string;
  id: number;
};
export interface ChartSpecCreate {
  name: string;
  description: string;
  spec: string;
};
export interface DatasetCreate {
  name: string;
  description: string;
  datajson: string;
};
export interface DatasetCreateResponse {
  name: string;
  description: string;
  datajson: string;
  id: number;
};
export interface DatasetTestPlanResponse {
  name: string;
  description: string;
  datajson: string;
  id: number;
};
export interface DemoResult {
  testplan_name: string;
  data_collection_event_name: string;
  unique_respondents: number;
  info_all_correct: DemoResultInfoAllCorrect;
  title: string;
  question_results: DemoResultQuestion[];
};
export interface DemoResultConfigCreate {
  testplan_id: number;
  healthcare_ai_stimulus_id: number;
  result_page_title: string;
};
export interface DemoResultConfigCreateResponse {
  testplan_id: number;
  healthcare_ai_stimulus_id: number;
  result_page_title: string;
  id: number;
};
export interface DemoResultOptionInfo {
  id: number;
  text: string;
  index: number;
  correct: boolean;
  p_value: number | null;
  healthcare_ai_proportion: number;
  plain_proportion: number;
};
export interface DemoResultQuestion {
  id: number;
  text: string;
  index: number;
  healthcare_ai_respondents: number;
  plain_respondents: number;
  option_info: DemoResultOptionInfo[];
};
export interface DemoResultInfoAllCorrect {
  plain_proportion: number;
  healthcare_ai_proportion: number;
  p_value: number | null;
}
export interface OptionCreate {
  text: string;
  index: number;
  correct: boolean;
};
export interface OptionFullResponse {
  text: string;
  index: number;
  correct: boolean;
  id: number;
  question_id: number;
};
/**
 * Don't include information about correct options
 * if we are passing the info to the user.
 */
export interface OptionRespondentFacing {
  text: string;
  index: number;
  id: number;
  question_id: number;
};
export interface QuestionCreate {
  text: string;
  multi_select: boolean;
  options: OptionCreate[];
};
/** Use TestPlan options instead of full options. */
export interface QuestionFullResponse {
  text: string;
  multi_select: boolean;
  id: number;
  options: OptionFullResponse[];
};
/** Use TestPlan options instead of full options. */
export interface QuestionRespondentFacing {
  text: string;
  multi_select: boolean;
  id: number;
  options: OptionRespondentFacing[];
};
export interface ResponseCreate {
  user_email: string;
  testplan_id: number;
  data_collection_event_id: number | null;
  stimulus_id: number;
  question_id: number;
  displayed_timestamp: string;
  answered_timestamp: string;
  option_ids?: number[];
  timer_expired: boolean;
};
export interface ResponseCreateResponse {
  user_email: string;
  testplan_id: number;
  data_collection_event_id: number | null;
  stimulus_id: number;
  question_id: number;
  displayed_timestamp: string;
  answered_timestamp: string;
  option_ids?: number[];
  id: number;
};
export interface StaticChartCreate {
  name: string;
  description: string;
  image: string;
};
export interface StaticChartCreateResponse {
  name: string;
  description: string;
  image: string;
  id: number;
};
export interface StaticChartFullResponse {
  name: string;
  description: string;
  id: number;
  image: string;
};
export interface StaticPageCreate {
  name: string;
  description: string;
  image: string;
};
export interface StaticPageCreateResponse {
  name: string;
  description: string;
  image: string;
  id: number;
};
export interface StaticPageFullResponse {
  name: string;
  description: string;
  id: number;
  image: string;
};
export interface StimulusBundleCreate {
  stimulus_id: number;
  staticpages: StimulusBundleStaticPages[];
};
export interface StimulusBundleCreateResponse {
  stimulus_id: number;
  staticpages: StimulusBundleStaticPages[];
  id: number;
};
export interface StimulusBundleFullResponse {
  stimulus_id: number;
  staticpages: StimulusBundleFullStaticPage[];
  id: number;
  stimulus: StimulusFullResponse;
};
export interface StimulusBundleFullStaticPage {
  index: number;
  timer_seconds: number;
  staticpage: StaticPageFullResponse;
};
export interface StimulusBundleStaticPages {
  staticpage_id: number;
  index: number;
  timer_seconds: number;
};
export interface StimulusCreate {
  name: string;
  description: string;
  type: StimulusType;
  chartspec_id: number | null;
  dataset_id: number | null;
  static_chart_id: number | null;
};
export interface StimulusCreateResponse {
  name: string;
  description: string;
  type: StimulusType;
  chartspec_id: number | null;
  dataset_id: number | null;
  static_chart_id: number | null;
  id: number;
};
export interface StimulusFullResponse {
  name: string;
  description: string;
  type: StimulusType;
  id: number;
  chartspec: ChartSpec | null;
  dataset: DatasetTestPlanResponse | null;
  static_chart: StaticChartFullResponse | null;
};
/** An enumeration. */
export enum StimulusType{
  Static = "static",
  VegaLite = "vegailite",
}
export interface TestPlanCreate {
  name: string;
  description: string;
  mode: TestPlanMethod;
  display_results: boolean;
  stimulus_bundles: TestPlanCreateStimulusBundle[];
  questions: TestPlanCreateQuestion[];
};
export interface TestPlanCreateQuestion {
  question_id: number;
  index: number;
  timer_seconds: number;
};
export interface TestPlanCreateResponse {
  name: string;
  description: string;
  mode: TestPlanMethod;
  display_results: boolean;
  id: number;
};
export interface TestPlanCreateStimulusBundle {
  stimulus_bundle_id: number;
  index: number;
};

export interface TestPlanRespondentQuestion {
  index: number;
  timer_seconds: number;
  question: QuestionRespondentFacing;
};
export interface TestPlanFullQuestion {
  index: number;
  timer_seconds: number;
  question: QuestionFullResponse;
};
export interface TestPlanRespondentFacing {
  name: string;
  description: string;
  mode: TestPlanMethod;
  display_results: boolean;
  id: number;
  stimulus_bundles: TestPlanFullStimulusBundle[];
  questions: TestPlanFullQuestion[];
};
export interface TestPlanFull {
  name: string;
  description: string;
  mode: TestPlanMethod;
  display_results: boolean;
  id: number;
  stimulus_bundles: TestPlanFullStimulusBundle[];
  questions: TestPlanFullQuestion[];
  data_collection_events: DataCollectionEvent[]
};
export interface TestPlanFullStimulusBundle {
  index: number;
  stimulus_bundle: StimulusBundleFullResponse;
};

export interface TestPlanSummary {
  name: string;
  description: string;
  mode: TestPlanMethod;
  display_results: boolean;
  id: number;
}
/** An enumeration. */
export enum TestPlanMethod {
  Independent = "independent",
  Paired = "paired",
}
export interface ActiveTestplanId {
  id: number;
}
export interface DataCollectionEventCreate {
  testplan_id: number
  name: string
  description: string
}
export interface DataCollectionEvent {
  id: number
  testplan_id: number
  name: string
  description: string
  created: string
}


// export interface ChartSpec {
//   name: string;
//   description: string;
//   spec: string;
//   id: number;
// };
// export interface ChartSpecCreate {
//   name: string;
//   description: string;
//   spec: string;
// };
// export interface DatasetCreate {
//   name: string;
//   description: string;
//   datajson: string;
// };
// export interface DatasetCreateResponse {
//   name: string;
//   description: string;
//   datajson: string;
//   id: number;
// };
// export interface DatasetTestPlanResponse {
//   name: string;
//   description: string;
//   datajson: string;
//   id: number;
// };
// export interface DemoResultOptionInfo {
//   id: number;
//   text: string;
//   index: number;
//   correct: boolean;
//   p_value: number | null;
//   healthcare_ai_proportion: number;
//   plain_proportion: number;
// };
// export interface DemoResultQuestion {
//   id: number;
//   text: string;
//   index: number;
//   healthcare_ai_respondents: number;
//   plain_respondents: number;
//   option_info: DemoResultOptionInfo[];
// };
// export interface DemoResult {
//   testplan_name: string;
//   unique_respondents: number;
//   title: string;
//   question_results: DemoResultQuestion[];
// };
// export interface HTTPValidationError {
//   detail?: ValidationError[];
// };
// export interface Option {
//   text: string;
//   index: number;
//   id: number;
//   question_id: number;
// };
// export interface OptionCreate {
//   text: string;
//   correct: boolean;
//   index: number;
// };
// export interface Question {
//   dataset_id: number;
//   text: string;
//   multi_select: boolean;
//   options: Option[];
//   id: number;
// };
// export interface QuestionCreate {
//   dataset_id: number;
//   text: string;
//   multi_select: boolean;
//   options: OptionCreate[];
// };
// export interface ResponseCreate {
//   user_email: string;
//   testplan_id: number;
//   stimulus_id: number;
//   question_id: number;
//   displayed_timestamp: string;
//   answered_timestamp: string;
//   option_ids?: number[];
// };
// export interface ResponseCreateResponse {
//   user_email: string;
//   testplan_id: number;
//   stimulus_id: number;
//   question_id: number;
//   displayed_timestamp: string;
//   answered_timestamp: string;
//   option_ids?: number[];
//   id: number;
// };
// export interface StaticPageCreate {
//   name: string;
//   description: string;
//   image: string;
// };
// export interface StaticPageCreateResponse {
//   name: string;
//   description: string;
//   image: string;
//   id: number;
// };
// export interface StaticPageFullResponse {
//   name: string;
//   description: string;
//   image: string;
//   id: number;
// };
// export interface StimulusBundleCreate {
//   stimulus_id: number;
//   staticpages?: StimulusBundleStaticPages[];
// };
// export interface StimulusBundleCreateResponse {
//   stimulus_id: number;
//   staticpages?: StimulusBundleStaticPages[];
//   id: number;
// };
// export interface StimulusBundleFullResponse {
//   stimulus_id: number;
//   staticpages: StimulusBundleFullStaticPage[];
//   id: number;
//   stimulus: StimulusFullResponse;
// };
// export interface StimulusBundleFullStaticPage {
//   index: number;
//   timer_seconds: number;
//   staticpage: StaticPageFullResponse;
// };
// export interface StimulusBundleStaticPages {
//   staticpage_id: number;
//   index: number;
//   timer_seconds: number;
// };
// export interface StimulusCreate {
//   name: string;
//   description: string;
//   chartspec_id: number;
//   dataset_id: number;
// };
// export interface StimulusCreateResponse {
//   name: string;
//   description: string;
//   chartspec_id: number;
//   dataset_id: number;
//   id: number;
// };
// export interface StimulusFullResponse {
//   name: string;
//   description: string;
//   id: number;
//   chartspec: ChartSpec;
//   dataset: DatasetTestPlanResponse;
// };
// export interface TestPlanCreate {
//   name: string;
//   description: string;
//   mode: TestPlanMethod;
//   display_results: boolean;
//   stimulus_bundles: TestPlanCreateStimulusBundle[];
//   questions: TestPlanCreateQuestion[];
// };
// export interface TestPlanCreateQuestion {
//   question_id: number;
//   index: number;
// };
// export interface TestPlanCreateResponse {
//   name: string;
//   description: string;
//   display_results: boolean;
//   mode: TestPlanMethod;
//   id: number;
// };
// export interface TestPlanCreateStimulusBundle {
//   stimulus_bundle_id: number;
//   index: number;
// };
// export interface TestPlanFullQuestion {
//   index: number;
//   question: Question;
// };
// export interface TestPlanFullResponse {
//   name: string;
//   description: string;
//   mode: TestPlanMethod;
//   display_results: boolean;
//   id: number;
//   stimulus_bundles: TestPlanFullStimulusBundle[];
//   questions: TestPlanFullQuestion[];
// };
// export interface TestPlanFullStimulusBundle {
//   index: number;
//   stimulus_bundle: StimulusBundleFullResponse;
// };
// /** An enumeration. */
// export enum TestPlanMethod {
//   Independent = "independent",
//   Paired = "paired",
// }
// export interface ValidationError {
//   loc: string[];
//   msg: string;
//   type: string;
// };


// // export interface ActiveTest {
// //   testplan_id: number;
// //   chart_pair: ChartPair;
// //   dataset: Dataset;
// //   questions: Question[];
// //   show_plain: boolean;
// //   show_enhanced: boolean;
// // };
// // export interface ChartPair {
// //   name: string;
// //   plain_spec: string;
// //   enhanced_spec: string;
// //   testplan_id: number;
// //   id: number;
// // };
// // export interface ChartPairCreate {
// //   name: string;
// //   plain_spec: string;
// //   enhanced_spec: string;
// //   testplan_id: number;
// // };
// // export interface ComparisonDetail {
// //   p_value: number;
// //   test_name: string;
// //   diff: number;
// //   lcl: number;
// //   ucl: number;
// // };
// // export interface Dataset {
// //   name: string;
// //   data: string;
// //   testplan_id: number;
// //   id: number;
// // };
// // export interface DatasetCreate {
// //   name: string;
// //   data: string;
// //   testplan_id: number;
// // };
// // export interface Epoch {
// //   name: string;
// //   active: boolean;
// //   id: number;
// //   created: string;
// // };
// // export interface EpochCreate {
// //   name: string;
// //   active: boolean;
// // };
// // export interface OptionCreate {
// //   text: string;
// //   correct: boolean;
// // };
// // export interface Option {
// //   id: number;
// //   question_id: number;
// //   text: string;
// //   correct: boolean;
// // };
// // export interface Question {
// //   text: string;
// //   testplan_id: number;
// //   multi_select: boolean;
// //   options: Option[];
// //   id: number;
// // };
// // export interface QuestionCreate {
// //   text: string;
// //   testplan_id: number;
// //   options: OptionCreate[];
// // };
// // export interface Response {
// //   option_id: number;
// //   testplan_id: number;
// //   id: number;
// //   timestamp: string;
// //   user_id: number;
// //   epoch_id: number;
// // };
// // export interface ResponseCreate {
// //   option_id: number;
// //   testplan_id: number;
// //   enhanced: boolean;
// //   displayed_timestamp: string;
// //   answered_timestamp: string;
// //   user_email: string;
// // };
// // export interface ResultDetail {
// //   total: number;
// //   correct: number;
// //   proportion: number;
// //   lcl: number;
// //   ucl: number;
// // };
// // export interface TestPlan {
// //   name: string;
// //   active: boolean;
// //   method: string;
// //   id: number;
// //   created: string;
// // };
// // export interface TestPlanCreate {
// //   name: string;
// //   active: boolean;
// //   method: string;
// // };
// // export interface TestResult {
// //   testplan_id: number;
// //   plain: ResultDetail;
// //   enhanced: ResultDetail;
// //   comparison: ComparisonDetail;
// // };
// // export interface ValidationError {
// //   loc: string[];
// //   msg: string;
// //   type: string;
// // }; 