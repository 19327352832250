import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Table, TableHead, TableRow, TableBody, TableCell, Snackbar } from '@mui/material'

import './testplanDetail.css'
import * as api from '../../../../data/api-types'
import { getTestplanDetails, createDataCollectionEvent } from '../../../../data/api-io'
import { selectUserlabAccessToken } from '../../main/adminSlice';
import * as pills from '../../pills/pills'
import { NewEventModal } from './newEvent'
import { StimulusBundleDetails } from './stimulusBundleDetail'
import { QuestionDetail } from './questionDetail'
import { URLModal } from './urlModal'


interface TestplanDetailRouteParams {
    testplanId: string | undefined
}
interface TestplanDetailProps {
    onNavigateBack: ()=>void
    check401: (e: any)=>void
}
export function TestplanDetail(props: TestplanDetailProps){
    let { testplanId } = useParams<TestplanDetailRouteParams>()
    const userlabAccessToken = useSelector(selectUserlabAccessToken)
    const [testplanFull, setTestplanFull] = useState<api.TestPlanFull | null>(null)
    const [eventModalOpen, setEventModalOpen] = useState(false)
    const [eventLoading, setEventLoading] = useState(false)
    const check401 = props.check401
    // URL Modal State
    const [urlModalOpen, setUrlModalOpen] = useState(false)
    const [eventURLId, setEventURLId] = useState<number|null>(null)
    const [snackbarText, setSnackbarText] = useState("")
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    useEffect(() => {
        if (testplanId !== undefined && userlabAccessToken !== null){
            getTestplanDetails(userlabAccessToken, +testplanId)
                .then(res => {
                    setTestplanFull(res.data)
                })
                .catch(e => {
                    check401(e)
                    console.error(e)
                })
        }
    }, [testplanId, userlabAccessToken, check401])

    const onGetTestUrl = (eventId: number) => {
        console.log("asked for url for event id ", eventId)
        setEventURLId(eventId)
        setUrlModalOpen(true)
    }

    const onGetResultsUrl = (eventId: number) => {
        console.log("asked for results url for event id ", eventId)
        const url = `${document.location.origin}/results?testplan=${testplanId}&event=${eventId}`
        navigator.clipboard.writeText(url)
            .then(()=>{
                setSnackbarText("Result URL copied to clipboard")
                setSnackbarOpen(true)
            })
            .catch(e => console.error(e))
    }

    const onCloseUrlModal = (copied: boolean) => {
        if (copied){
            setSnackbarText("Test URL copied to clipboard")
            setSnackbarOpen(true)
        }
        setUrlModalOpen(false)
        setEventURLId(null)
    }

    const onAddEvent = (name: string, description: string) => {
        if (testplanFull !== null && userlabAccessToken !== null) {
            setEventLoading(true)
            const newEvent: api.DataCollectionEventCreate = {
                testplan_id: testplanFull.id,
                name,
                description,
            }
            createDataCollectionEvent(userlabAccessToken, newEvent)
                .then(res => {
                    const updatedTestplan: api.TestPlanFull = {
                        ...testplanFull,
                        data_collection_events: testplanFull.data_collection_events.concat(res.data),
                    }
                    setTestplanFull(updatedTestplan)
                    setEventLoading(false)
                    setEventModalOpen(false)
                })
                .catch(e => {
                    setEventLoading(false)
                    setEventModalOpen(false)
                    console.error(e)
                })
        }
    }

    if (testplanFull === null){
        return (
            <div id="testplan-details-load-container">
                <CircularProgress />
            </div>
        )
    }
    return (
        <div id="testplan-details-page">
            <TestplanDetailHeader 
                testplan={testplanFull} 
                onNavigateBack={props.onNavigateBack}
            />
            <SectionDivider text="Data-Collection Events" />
            <DataCollectionEvents 
                events={testplanFull.data_collection_events}
                onGetTestUrl={onGetTestUrl}
                onGetResultsUrl={onGetResultsUrl}
                onAddEvent={onAddEvent}
                eventModalOpen={eventModalOpen}
                setEventModalOpen={setEventModalOpen}
                eventLoading={eventLoading}
            />
            <SectionDivider text="Stimulus Bundles" />
            <StimulusBundleDetails 
                stimulusBundles={testplanFull.stimulus_bundles}
            />
            <SectionDivider text="Questions" />
            <QuestionDetail 
                questions={testplanFull.questions}
            />
            <URLModal 
                open={urlModalOpen}
                onClose={()=>onCloseUrlModal(false)}
                onDone={()=>onCloseUrlModal(true)}
                testplan={testplanFull}
                eventId={eventURLId}
            />
            <Snackbar
                open={snackbarOpen}
                onClose={()=>setSnackbarOpen(false)}
                autoHideDuration={2000}
                message={snackbarText}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
            />
        </div>
    )
}

interface TestplanDetailHeaderProps {
    testplan: api.TestPlanFull
    onNavigateBack: ()=>void
}
function TestplanDetailHeader(props: TestplanDetailHeaderProps){

    return (
        <div id="testplan-detail-header">
            <div id="back-arrow-container">
            <IconButton aria-label="back" onClick={props.onNavigateBack}>
                <ArrowBackRoundedIcon />
            </IconButton>
            </div>
            <h1 id="testplan-name">{props.testplan.name}</h1>
            <div id="testplan-header-pills">
                <pills.PillID 
                    text={props.testplan.id.toString()}
                />
                {
                    props.testplan.mode === api.TestPlanMethod.Independent ?
                    <pills.PillMethodIndependent />
                    :
                    <pills.PillMethodPaired />
                }
            </div>
            <p>{props.testplan.description}</p>
        </div>
    )
}

interface DataCollectionEventsProps {
    events: api.DataCollectionEvent[]   
    onGetTestUrl: (eventId: number) => void
    onGetResultsUrl: (eventId: number) => void
    onAddEvent: (name: string, description: string) => void
    eventModalOpen: boolean
    setEventModalOpen: (open: boolean)=>void
    eventLoading: boolean
}
function DataCollectionEvents(props: DataCollectionEventsProps){

    return (
        <div id="data-collection-events">
            <Table sx={{ minWidth: 650, marginBottom: 3 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">name</TableCell>
                    <TableCell align="left">description</TableCell>
                    <TableCell align="left">created</TableCell>
                    <TableCell align="left">id</TableCell>
                    <TableCell align="left">test url</TableCell>
                    <TableCell align="left">result url</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {props.events
                    .sort((a, b)=>{return a.id < b.id ? 1 : -1})
                    .map(e => {
                        const created = new Date(e.created)
                        return (
                        <TableRow
                        key={e.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row" width="150px">
                            {e.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {e.description}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {created.toLocaleDateString()}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <pills.PillID 
                                text={e.id.toString()} 
                            />
                        </TableCell>
                        <TableCell width="110px">
                            <div
                                className="data-collection-event-url-button test"
                                onClick={() => props.onGetTestUrl(e.id)}
                            >
                                test url
                            </div>
                        </TableCell>
                        <TableCell width="115px">
                            <div
                                className="data-collection-event-url-button results"
                                onClick={() => props.onGetResultsUrl(e.id)}
                            >
                                result url
                            </div>
                        </TableCell>
                        </TableRow>
                        )
                    })
                }
                </TableBody>
            </Table>
            <div
                className="new-data-collection-event-button"
                onClick={() => props.setEventModalOpen(true)}
            >
                <span style={{marginRight: 5}}><AddIcon/></span>
                <span style={{whiteSpace: "nowrap"}}>new event</span>
            </div>
            <NewEventModal 
                open={props.eventModalOpen}
                loading={props.eventLoading}
                onClose={()=>{props.setEventModalOpen(false)}}
                onAdd={props.onAddEvent}
            />
        </div>
    )
}

interface SectionDividerProps {
    text: string
}
function SectionDivider(props: SectionDividerProps){
    return (
        <div className="section-divider">
            {props.text}
        </div>
    )
}