import './questionDetail.css'
import * as api from '../../../../data/api-types'
import * as pills from '../../pills/pills'
import GreenCheck from './icons/green_check.png'

interface QuestionDetailProps {
    questions: api.TestPlanFullQuestion[]
}
export function QuestionDetail(props: QuestionDetailProps){
    return (
        <div id="question-details">
            {props.questions.map(q => <SingleQuestion question={q} key={q.question.id}/>)}
        </div>
    )
}

interface SingleQuestionProps {
    question: api.TestPlanFullQuestion
}
function SingleQuestion(props: SingleQuestionProps){
    const timerText = `${props.question.timer_seconds}s`
    return (
        <div className="single-question-details">
            <h2 className="single-question-title">
                {props.question.index + 1}. {props.question.question.text}
            </h2>
            <div className="question-detail-pills">
                {
                props.question.question.multi_select ?
                <pills.PillModeMulti />
                :
                <pills.PillModeSingle />
                }
                <pills.PillTimer text={timerText}/>
            </div>
            <div className="single-question-options">
                {
                props.question.question.options.map(o => {
                    return <SingleOption option={o} key={o.id}/>
                })
                }
            </div>
        </div>
    )
}

interface SingleOptionProps {
    option: api.OptionFullResponse
}
function SingleOption(props: SingleOptionProps){
    return (
        <div className="single-option">
            {   
                props.option.correct ?
                <img src={GreenCheck} alt="check mark" />
                :
                <span className="checkmark-placeholder"></span>
            }
            <span>{props.option.text}</span>
        </div>
    )
}

