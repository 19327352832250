import { Dialog, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core'

import * as api from '../../../../data/api-types'


interface URLModalProps{
    open: boolean
    onClose: ()=>void
    onDone: ()=>void
    testplan: api.TestPlanFull
    eventId: number | null
}
export function URLModal(props: URLModalProps){

    const stimulusBundleIdSelected = (id: number) => {
        if (props.eventId !== null) {
            copyUrl(props.testplan.id, id, props.eventId)
        }
        else {
            throw new Error("This shouldn't happen")
        }
    } 


    const copyUrl = (
        testplanId: number,
        stimulusBundleId: number,
        eventId: number,
    ) => {
        let url: string = ""
        if (stimulusBundleId === -1){
            // Don't specify a stimulusBundle ID, letting the user move through both
            url = `${document.location.origin}?testplan=${testplanId}&event=${eventId}`
        }
        else {
            url = `${document.location.origin}?testplan=${testplanId}&stimulusBundle=${stimulusBundleId}&event=${eventId}`
        }
        navigator.clipboard.writeText(url)
            .then(()=>{
                props.onDone()
            })
            .catch(e => console.error(e))

    }

    let allText = "Both"
    if (props.testplan.stimulus_bundles.length > 2) {
        allText = "All"
    }

    return (
        <Dialog onClose={props.onClose} open={props.open}>
        <DialogTitle>Please select a stimulus bundle</DialogTitle>
        <List>
            {
            props.testplan.stimulus_bundles.map(sb => {
                return (
                    <ListItem 
                    button 
                    onClick={()=>stimulusBundleIdSelected(sb.stimulus_bundle.id)}
                    key={sb.stimulus_bundle.id}
                    >
                        <ListItemText 
                        primary={sb.stimulus_bundle.stimulus.name} 
                        secondary={sb.stimulus_bundle.stimulus.description}
                        />
                    </ListItem>
                )
            })
            }
            <ListItem button onClick={()=>stimulusBundleIdSelected(-1)}>
                <ListItemText 
                primary={allText}
                secondary="Selecting this option will present all of the stimulus bundles to the user sequentially."
                />
            </ListItem>
        </List>
        </Dialog>
    )
}