import {scaleLinear as d3scaleLinear} from "d3-scale"
import {select as d3select} from "d3-selection"
import {axisBottom as d3axisBottom} from "d3-axis"
import {line as d3line} from "d3-shape"


interface ChartDatum {
    index: number
    proportion: number
}

interface RenderArgs {
    targetSelector: string
    chartWidth: number
    barHeight: number
    lineHeight: number
    startPadding: number
    data: ChartDatum[]
    barColor: string
    orientLeft: boolean
}

export default function render(args: RenderArgs){
    const data = args.data
    const left = args.orientLeft
    // Set up drawing area
    // Constrain the width but allow the chart to vary vertically
    // depending on the number of elements in "data"
    const totalSvgWidth = args.chartWidth
    const lineHeight = args.lineHeight
    // How much extra space should be added before the first bar
    const startPadding = args.startPadding

    // Empty space at edges of chart
    const xLeftGutter = 20
    const xRightGutter = 20
    const yTopGutter = 25
    const yBottomGutter = 0

    // Extra X padding between edges and axes
    const barLabelXWidth = 30 

    const totalXLeftMargin = left ? xRightGutter : xLeftGutter + barLabelXWidth
    const totalXRightMargin = left ? xLeftGutter + barLabelXWidth : xRightGutter
    const xAxisWidth = totalSvgWidth - totalXLeftMargin - totalXRightMargin

    // Extra Y padding between edges and axes
    const tickLabelHeight = 15

    // Required drawing height
    const yAxisHeight = lineHeight * data.length + startPadding

    const totalYTopMargin = yTopGutter + tickLabelHeight
    const totalYBottomMargin = yBottomGutter
    const totalSvgHeight = yAxisHeight + totalYTopMargin + totalYBottomMargin

    // Style constants
    const barHeight = args.barHeight
    
    // Create the svg element and add the gutters on left and top
    // const h1 = d3select("h1")
    const svg = d3select(args.targetSelector).insert("svg", "h1")
        .attr("height", totalSvgHeight)
        .attr("width", totalSvgWidth)
    const drawG = svg.append("g")
        .attr("transform", `translate(${xLeftGutter},${yTopGutter})`)
    const axesXTranslate = left ? 0 : barLabelXWidth
    const axesG = drawG.append("g")
        .attr("transform", `translate(${axesXTranslate},${tickLabelHeight})`)
    
    // Set up the x scale
    const xRange = left ? [xAxisWidth, 0] : [0, xAxisWidth]
    const xScale = d3scaleLinear()
        .domain([0, 1])
        .range(xRange)


    // Set up the dashed vertical lines
    const dashedLineData: ([number, number] | null)[] = [
        [xScale(.25), 0], [xScale(.25), yAxisHeight],
        null,
        [xScale(.50), 0], [xScale(.50), yAxisHeight],
        null,
        [xScale(.75), 0], [xScale(.75), yAxisHeight],
        null,
        [xScale(1), 0], [xScale(1), yAxisHeight],
        null,
    ]
    const dashedLineGen = d3line<[number, number] | null>().defined(x=>x!==null)
    axesG.append("path")
        .attr("d", dashedLineGen(dashedLineData))
        .attr("stroke", "#aaa")
        .style("stroke-dasharray", (3))
        .style("stroke-width", 2);

    // Set up the x axis
    const axis = d3axisBottom(xScale)
        .tickArguments([3, "%"])
        .tickSize(0)
    const xAxisG = axesG.append("g")
        .call(axis)
    xAxisG.selectAll("text")
        .attr("transform", `translate(0, -${tickLabelHeight})`)
    xAxisG.select(".domain")
        .style("stroke-width", 2)
        .style("stroke", "#555");

    // Set up the bars
    axesG.selectAll(".bar").data<ChartDatum>(data)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", d => {
            if (left){
                return xScale(d.proportion)
            }
            return d.proportion
        })
        .attr("y", d => barYFromIndex(d.index, lineHeight, startPadding))
        .attr("width", d => {
            if (left){
                return xScale(0) - xScale(d.proportion)
            }
            return xScale(d.proportion)
        })
        .attr("height", barHeight)
        .attr("fill", args.barColor)
    
    // Set up the y axis
    const yAxisG = axesG.append("g")
    const yAxisData: [number, number][] = [[xScale(0), 0], [xScale(0), yAxisHeight]]
    yAxisG.append("path")
        .attr("d", d3line()(yAxisData))
        .attr("stroke", "#555")
        .style("stroke-width", 2);

    
    // Add the y-axis labels
    const labelX = left ? xAxisWidth + 7 : -8
    const textAnchor = left ? "start" : "end"
    axesG.selectAll(".ylabel").data<ChartDatum>(data)
        .enter()
        .append("text")
        .text(d => `${(d.proportion * 100).toFixed(0)}%`)
        .attr("x", labelX)
        .attr("y", d => barYFromIndex(d.index, lineHeight, startPadding) + barHeight / 2 + 2)
        .attr("text-anchor", textAnchor) 
        .attr("alignment-baseline", "middle")
        .attr("class", "ylabel")
}   

function barYFromIndex(index: number, lineHeight: number, padding: number){
    // center the bar on the line with extra padding at the beginning
    return (index * lineHeight) + (lineHeight / 2) + padding
}