import './pills.css'


interface PillBaseProps {
    color: string
    leftText: string
    rightText: string
}
function PillBase(props: PillBaseProps){
    return (
        <span
            className="pill-base-container"
        >
            <span 
            style={{borderColor: props.color, backgroundColor: props.color}}
            className="pill-left-text">
                {props.leftText}
            </span>
            <span 
            style={{borderColor: props.color, color: props.color}}
            className="pill-right-text">
                {props.rightText}
            </span>
        </span>
    )
}

interface DynamicPillProps {
    text: string
}
function DynamicPillFactory(color: string, leftText: string) {
    return function DynamicPill(props: DynamicPillProps){
        return (
            <PillBase 
                color={color} 
                leftText={leftText} 
                rightText={props.text}
            />
        )
    }
}
export const PillMinTime = DynamicPillFactory("#9671FF", "minimum time")
export const PillTimer = DynamicPillFactory("#9671FF", "timer")
export const PillID = DynamicPillFactory("#B2B2B2", "id")

function StaticPillFactory(color: string, leftText: string, rightText: string){
    return function StaticPill(){
        return (
            <PillBase 
                color={color} 
                leftText={leftText} 
                rightText={rightText}
            />
        )
    }
}
export const PillMethodIndependent = StaticPillFactory("#FF68A7","method", "independent")
export const PillMethodPaired = StaticPillFactory("#16D5FF","method", "paired")
export const PillTypeStatic = StaticPillFactory("#FF7070","type", "static")
export const PillTypeInteractive = StaticPillFactory("#006DEE","type", "interactive")
export const PillModeSingle = StaticPillFactory("#BADB36","mode", "single")
export const PillModeMulti = StaticPillFactory("#FF9921","mode", "multi")