// See https://www.npmjs.com/package/@azure/msal-react
import { useEffect, useState } from "react"
import {Switch, Route, useHistory, useRouteMatch, Redirect} from 'react-router-dom'
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { 
    InteractionStatus,
    AuthenticationResult,
    InteractionRequiredAuthError,
    SilentRequest,
} from "@azure/msal-browser";
import { useDispatch } from "react-redux";

import { loginRequest, userlabTokenScopes } from "./authConfig";
import { Grid } from "react-loader-spinner";
import { AdminHeader } from '../header/adminHeader'
// import { AdminTabs } from './adminTabs'
import { Testplans } from '../testplans/testplans'
import './adminMain.css'
import ForbiddenIcon from './icons/forbidden.png'
import { ManageAdmins } from '../manage-admins/manageAdmins'
import { setUserlabAccessToken, setGraphAccessToken } from './adminSlice'


interface AdminMainProps {
    routeBase: string
}
export default function AdminMain(props: AdminMainProps) {
    const isAuthenticated = useIsAuthenticated()
    const msal = useMsal()
    const history = useHistory()
    const { url } = useRouteMatch()
    const dispatch = useDispatch()
    const [userlabAccessTokenResponse, setUserlabAccessTokenResponse] = useState<AuthenticationResult | null>(null)
    const [graphAccessTokenResponse, setGraphAccessTokenResponse] = useState<AuthenticationResult | null>(null)


    useEffect(() => {
        if (!isAuthenticated && msal.inProgress === InteractionStatus.None){
            msal.instance.loginRedirect(loginRequest)
                .catch(e => console.error(e))
        }
    }, 
    // eslint-disable-next-line
    [isAuthenticated, msal.inProgress]
    )

    useEffect(()=>{
        if (isAuthenticated && userlabAccessTokenResponse === null){
            // Get an Access Token for our API
            // See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
            const userlabTokenRequest: SilentRequest = {
                scopes: userlabTokenScopes,
                account: msal.accounts[0],
            }
            msal.instance.acquireTokenSilent(userlabTokenRequest)
                .then(tokenResponse => {
                    setUserlabAccessTokenResponse(tokenResponse)
                    dispatch(setUserlabAccessToken(tokenResponse.accessToken))
                })
                .catch(error => {
                    if (error instanceof InteractionRequiredAuthError) {
                        // fallback to interaction when silent call fails
                        return msal.instance.acquireTokenRedirect(userlabTokenRequest)
                    } else {
                        console.error(error)
                    }
                }); 
        }
        if (isAuthenticated && graphAccessTokenResponse === null){
            // Get an Access Token for Microsoft Graph
            // See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
            const graphTokenRequest: SilentRequest = {
                scopes: ["User.Read"],
                account: msal.accounts[0],
            }
            msal.instance.acquireTokenSilent(graphTokenRequest)
                .then(tokenResponse => {
                    setGraphAccessTokenResponse(tokenResponse)
                    dispatch(setGraphAccessToken(tokenResponse.accessToken))
                })
                .catch(error => {
                    if (error instanceof InteractionRequiredAuthError) {
                        // fallback to interaction when silent call fails
                        return msal.instance.acquireTokenRedirect(graphTokenRequest)
                    } else {
                        console.error(error)
                    }
                }); 
        }
    },
    // eslint-disable-next-line
    [isAuthenticated, userlabAccessTokenResponse, graphAccessTokenResponse]
    )


    const signOut = () => {
        if (msal.accounts.length === 1){
            const account = msal.accounts[0]
            msal.instance.logoutRedirect({account})
                .then(() => {
                    dispatch(setUserlabAccessToken(null))
                })
                .catch(e => console.error(e))
        } else {
            msal.instance.logoutRedirect()
        }
        
    }

    const onManageAdminsClicked = () => {
        history.replace(`${props.routeBase}/manage-admins`)
    }

    // Check if we got a 403 back from the API, and if so navigate
    // to the 403 page
    const check403 = (e: any) => {
        if (e.isAxiosError !== undefined){
            if (e.response.status === 403){
                history.replace(`${url}/403`)
            }
        } 
    }

    // Check if we got a 401 back from the API, and if so navigate
    // to the /admin page which will start the authentication flow
    const check401 = (e: any) => {
        if (e.isAxiosError !== undefined){
            if (e.response.status === 401){
                history.replace(url)
            }
        } 
    }

    let username = ""
    if (userlabAccessTokenResponse !== null){
        if (userlabAccessTokenResponse.account !== null){
            const name = userlabAccessTokenResponse.account.name
            if (name !== undefined){
                username = name
            } else {
                username = userlabAccessTokenResponse.account.username
            }
        }
    }

    return (
        <div id="admin-page">
            <AdminHeader 
                username={username} 
                onSignOut={signOut} 
                onManageAdmins={onManageAdminsClicked}
                routeBase={props.routeBase}
            />
            {/* <AdminTabs routeBase={props.routeBase} links={[
                {name: "Testplans", link: "/testplans"},
                {name: "Stimuli", link: "/stimuli"},
                {name: "Questions", link: "/questions"},
            ]}/> */}
            <Switch>
            <Route path={`${props.routeBase}/403`}>
                <AdminForbidden />
            </Route>
            <Route path={`${props.routeBase}/manage-admins`}>
                <ManageAdmins check403={check403}/>
            </Route>
            <Route path={`${props.routeBase}/testplans`}>
                <Testplans check401={check401} check403={check403}/>
            </Route>
            <Route path={props.routeBase}>
                {
                isAuthenticated && userlabAccessTokenResponse !== null ?
                <Redirect to={`${props.routeBase}/testplans`} />
                :
                <div className="spinner-container">
                    <Grid color="#00BFFF" height={80} width={80}/>
                </div>
                }
            </Route>
            </Switch>
        </div>
    )
}


interface AdminForbiddenProps {}
export function AdminForbidden(props: AdminForbiddenProps){
    return (
        <div id="forbidden-page">
            <div id="forbidden-content">
                <div id="forbidden-main">
                    <img src={ForbiddenIcon} alt="Red X" />
                    <h1>Forbidden</h1>
                </div>
                <p>
                    It's nothing personal, you just don't have UserLab administrator permissions. <br/>
                    If you would like them please ask an administrator
                    to grant you access.
                </p>
            </div>
        </div>
    )
}