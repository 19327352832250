import { useState } from 'react'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DiaglogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box';


interface NewEventModalProps {
    open: boolean
    loading: boolean
    onClose: ()=>void
    onAdd: (name: string, description: string)=>void
}
export function NewEventModal(props: NewEventModalProps){
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [addDisabled, setAddDisabled] = useState(true)

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
        if (e.target.value.length > 0 && description.length > 0){
            setAddDisabled(false)
        } else if (!addDisabled) {
            setAddDisabled(true)
        }
    }

    const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
        if (e.target.value.length > 0 && name.length > 0){
            setAddDisabled(false)
        } else if (!addDisabled) {
            setAddDisabled(true)
        }
    }

    const onAddEvent  = ()=>{
        setAddDisabled(true)
        props.onAdd(name, description)
    }

    const onClose = () => {
        setName("")
        setDescription("")
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={onClose}>
            <DiaglogTitle>Add a New Data-Collection Event</DiaglogTitle>
            <DialogContent>
                <Box
                    component="form"
                    sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <DialogContentText>
                        Note that the event's name will appear on the results page, which is visible to the user.
                        The description field is only visible to UserLab administrators.
                    </DialogContentText>
                    <TextField
                        variant="outlined"
                        label="name"
                        onChange={onNameChange}
                        style={{width: "95%"}}
                        autoFocus
                        
                    />
                    <TextField
                        variant="outlined"
                        multiline
                        minRows={3}
                        label="description"
                        onChange={onDescriptionChange}
                        style={{width: "95%"}}
                        
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={onClose}>cancel</Button>
                <LoadingButton 
                    variant="contained" 
                    color="success" 
                    onClick={onAddEvent}
                    loading={props.loading}
                    disabled={addDisabled}
                >
                    create  event
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}