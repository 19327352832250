import { useRef, useState, useEffect } from 'react'
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import { Menu, MenuItem, Avatar } from '@mui/material';
import { deepPurple } from '@mui/material/colors';

import './adminHeader.css'
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectGraphAccessToken } from '../main/adminSlice';
import { getUserPhoto } from '../../../data/api-io';
import { useAccount, useMsal } from '@azure/msal-react';


export interface AdminHeaderProps {
    username: string;
    onSignOut: ()=>void;
    onManageAdmins: ()=>void;
    routeBase: string
}
export function AdminHeader(props: AdminHeaderProps) {
    const [settingsMenuOpen, setSettingsMenuOpen] = useState(false)
    const [userMenuOpen, setUserMenuOpen] = useState(false)
    const settingsRef = useRef<HTMLSpanElement>(null)
    const userRef = useRef<HTMLSpanElement>(null)
    const history = useHistory()
    const msal = useMsal()
    const account = useAccount(msal.accounts[0] || {})
    const graphAccessToken = useSelector(selectGraphAccessToken)
    const [userPhotoURL, setUserPhotoURL] = useState<string|undefined>(undefined)

    const manageAdminsClicked = () => {
        setSettingsMenuOpen(false)
        props.onManageAdmins()
    }

    useEffect(()=>{
        if (graphAccessToken !== null && userPhotoURL === undefined){
            getUserPhoto(graphAccessToken)
                .then(res => {
                    setUserPhotoURL(URL.createObjectURL(res.data))
                })
                .catch(e => console.error(e))
        }
    }, [graphAccessToken, userPhotoURL])

    let avatarInitials: string | JSX.Element = <PersonIcon />
    if (account !== null && account.name !== undefined){
        const nameParts = account.name.split(' ')
        const last = nameParts[nameParts.length - 1]
        avatarInitials = `${nameParts[0][0]}${last[0]}`.toUpperCase()
    }

    return (
        <div id="admin-header">
            <div id="admin-header-title" onClick={()=>{history.replace(props.routeBase)}}>
                <span>UserLab</span>
                <span>Admin</span>
            </div>
            <div id="admin-header-right-items">
                <span id="admin-header-setting-icon" ref={settingsRef}>
                    <SettingsIcon onClick={()=>setSettingsMenuOpen(true)} />
                </span>
                <span
                    id="admin-header-avatar-container"
                    ref={userRef}
                    onClick={()=>setUserMenuOpen(true)}
                >
                    <span 
                        id="admin-header-username"
                    >{props.username}</span>
                    {
                        userPhotoURL ?
                        <Avatar alt="Your Microsoft profile photo." src={userPhotoURL} />
                        :
                        <Avatar sx={{ bgcolor: deepPurple[500] }}>{avatarInitials}</Avatar>
                    }
                </span>
                
                
            </div>
            <Menu
                open={settingsMenuOpen}
                anchorEl={settingsRef.current}
                onClose={()=>setSettingsMenuOpen(false)}
            >
                <MenuItem onClick={manageAdminsClicked}>Manage Administrators</MenuItem>
            </Menu>
            <Menu
                open={userMenuOpen}
                anchorEl={userRef.current}
                onClose={()=>setUserMenuOpen(false)}
            >
                <MenuItem onClick={props.onSignOut}>Sign Out</MenuItem>
            </Menu>
        </div>
    )
}
