import axios from 'axios'

import * as api from './api-types'


/**** Setup ****/
export function getBaseURL(){
    // Returns the baseURL for the server.
    switch (process.env.NODE_ENV) {
        case "development":
            return `http://${document.location.hostname}:8000`
        case "test":
            return `http://${document.location.hostname}:8000`
        case "production":
            // This will cause the app to use the hostname it was served from
            return ""
        default:
            console.error(`process.env is not recognized: ${process.env.NODE_ENV}`)
    }
    
}

// Default to DEV endpoints
let baseHASUrl = ""
let cdn = ""
switch (window.location.hostname){
    case "userlab.hcdatascienceservices.dev":
        baseHASUrl = "https://dev-has-portal-svc.azurewebsites.net"
        cdn = "https://userlab.azureedge.net/dev"
        break
    case "userlab.hcdatascienceservices.com":
        baseHASUrl = "https://api.has21v.com"
        cdn = "https://userlab.azureedge.net/prod"
        break
    default:
        // This is the default case so we can handle both localhost
        // and whatever local IP address we might be using to test
        // the mobile experience.
        baseHASUrl = "https://dev-has-portal-svc.azurewebsites.net"
        cdn = "https://userlab.azureedge.net/local"
}   
    
/****** Demo Test Mode ******/
export function getTestPlanRespondentFacing(recaptchaToken: string, testplanId: number){
    // Check the "mode" of the testplan, and if it is "independent"
    // select only one of the stimulus bundles at random.
    return axios.create({
        headers: {"ReCaptcha": recaptchaToken}
    }).get<api.TestPlanFull>(`${cdn}/testplans/${testplanId}`)
    .then(res => {
        if (res.data.mode === api.TestPlanMethod.Independent){
            const stimulus = pickRandomSimulus(res.data.stimulus_bundles)
            res.data.stimulus_bundles = [stimulus]
        }
        addMissingFields(res.data)
        return res
    })
}

function pickRandomSimulus(stimuli: api.TestPlanFullStimulusBundle[]): api.TestPlanFullStimulusBundle {
    const index = Math.floor(Math.random() * stimuli.length)
    return stimuli[index]
}

function addMissingFields(testPlan: api.TestPlanFull): void {
    // Since we are caching testplans in the CDN when we make schema changes we can either update
    // all the cached testplans or add a default here. For example, we added a timer to each question,
    // however we had already created several testplans when we did that, so the JSON in the CDN does
    // not contain a 'timer_seconds' field. So we add it here. We should also update it in the cache,
    // but even then we will still have to wait for the cache to expire before it actually updates
    // in the CDN response.
    for (let q of testPlan.questions) {
        if (!("timer_seconds" in q)){
            q.timer_seconds = 30
        }
    }
}

export function submitResponse(
    recaptchaToken: string,
    userEmail: string,
    testplanId: number,
    dataCollectionEventId: number | null,
    stimulusId: number,
    questionId: number,
    displayedTimestamp: Date,
    answeredTimestamp: Date,
    optionIds: number[],
    timerExpired: boolean,
){
    const payload: api.ResponseCreate = {
        user_email: userEmail,
        testplan_id: testplanId,
        data_collection_event_id: dataCollectionEventId,
        stimulus_id: stimulusId,
        question_id: questionId,
        option_ids: optionIds,
        displayed_timestamp: displayedTimestamp.toISOString(),
        answered_timestamp: answeredTimestamp.toISOString(),
        timer_expired: timerExpired,
    }
    return axios.request({
        url: `${getBaseURL()}/api/v1/responses`,
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "ReCaptcha": recaptchaToken,
        },
        data: payload,
    })
}

export function getDemoResults(recaptchaToken: string, testPlanId: number, dataCollectionEventId: number){
    return axios.create({
        headers: {"ReCaptcha": recaptchaToken}
    }).get<api.DemoResult>(`${getBaseURL()}/api/v1/testplans/${testPlanId}/data-collection-events/${dataCollectionEventId}/results`)
}


/**** Factories *****/
function GET<ResponseType>(endpoint: string) {
    const url = `${getBaseURL()}${endpoint}`
    return (authToken: string) => {
        return axios.request<ResponseType>({
            url,
            method: "GET",
            headers: {"Authorization": `Bearer ${authToken}`},
        })
    }
}
function DELETE<PayloadType, ResponseType>(endpoint: string) {
    const url = `${getBaseURL()}${endpoint}`
    return (authToken: string, payload: PayloadType) => {
        return axios.request<ResponseType>({
            url,
            method: "DELETE",
            headers: {"Authorization": `Bearer ${authToken}`},
            data: payload,
        })
    }
}
function POST<PayloadType, ResponseType>(endpoint: string) {
    const url = `${getBaseURL()}${endpoint}`
    return (authToken: string, payload: PayloadType) => {
        return axios.request<ResponseType>({
            url,
            method: "POST",
            headers: {"Authorization": `Bearer ${authToken}`},
            data: payload,
        })
    }
    
}


/****** Admin *****/
export const flushDB = DELETE<null, null>("/api/v1/admin/flush-db")
export const getAdmins = GET<api.AdministratorFull[]>("/api/v1/administrators")
export const createAdmin = POST<api.AdministratorCreate, api.AdministratorFull>("/api/v1/administrators")
export const deleteAdmin = DELETE<api.AdministratorDelete, api.AdministratorFull>("/api/v1/administrators")
export const getTestplanSummaries = GET<api.TestPlanSummary[]>("/api/v1/testplans/summaries")
export function getTestplanDetails(authToken: string, testplanId: number){
    const url = `${getBaseURL()}/api/v1/testplans/${testplanId}`
    return axios.request<api.TestPlanFull>({
        url,
        method: "GET",
        headers: {"Authorization": `Bearer ${authToken}`},
    })
}
export function getRawResponses(authToken: string, testplanId: number, callback: ()=>void){
    const url = `${getBaseURL()}/api/v1/responses/raw/${testplanId}`
    return axios.request<ResponseType>({
        url,
        method: "GET",
        headers: {"Authorization": `Bearer ${authToken}`},
        responseType: "blob",
    })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const filename = response.headers['x-filename']
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        callback()
      }).catch(e => {
        console.error(e)
        console.log(e.response)
      });
}
export function getUserPhoto(authToken: string){
    const url = `https://graph.microsoft.com/v1.0/me/photo/$value`
    return axios.request({
        url: url,
        method: "GET",
        headers: {"Authorization": `Bearer ${authToken}`},
        responseType: "blob",
    })
}
export const createDataCollectionEvent = POST<api.DataCollectionEventCreate, api.DataCollectionEvent>("/api/v1/data-collection-events")


/***** HAS *****/
export function getAuthTokenFromAPI(userEmail: string) {
    const url = baseHASUrl + "/authenticate"
    const payload = {
        email: userEmail,
    }
    return axios.request<api.HASAuthenticateResponse>({
        url: url,
        method: 'POST',
        data: payload,
    })
}

export function awardHASStamp(authToken: string, stampId: number) {
    const url = baseHASUrl + "/current-attendee.badge.add"
    const payload = {
        badgeId: stampId,
    }
    return axios.request({
        url,
        method: "POST",
        headers: {"Authorization": `Bearer ${authToken}`},
        data: payload,
    })
}
