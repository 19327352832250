import { configureStore } from '@reduxjs/toolkit'

import { HASInfoState, HASInfoReducer } from './components/user/login/HASInfoSlice'
import { userSliceReducer } from './components/user/login/userSlice'
import { testSliceReducer } from './components/user/userPage/testSlice'
import { adminSliceReducer } from './components/admin/main/adminSlice'


export interface GlobalState {
    HASInfo: HASInfoState
}

export const store = configureStore({
    reducer: {
        user: userSliceReducer,
        test: testSliceReducer,
        HASInfo: HASInfoReducer,
        admin: adminSliceReducer,
    },
  })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch