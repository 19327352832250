import { useState, useEffect, KeyboardEvent } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Dialog from '@mui/material/Dialog'
import DiaglogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import { getAdmins, createAdmin, deleteAdmin } from '../../../data/api-io'
import * as api from '../../../data/api-types'
import './manageAdmins.css'
import { useSelector } from 'react-redux';
import { selectUserlabAccessToken } from '../main/adminSlice';
import { Fragment } from 'react';


interface ManageAdminsProps {
    check403: (e: any)=>void
}
export function ManageAdmins(props: ManageAdminsProps) {
    const userlabAccessToken = useSelector(selectUserlabAccessToken)
    const [adminList, setAdminList] = useState<api.AdministratorFull[] | null>(null)
    const [newAdminOpen, setNewAdminOpen] = useState(false)
    const [removingAdmin, setRemovingAdmin] = useState<number | null>(null)
    const [loadingAdmin, setLoadingAdmin] = useState(false)
    const check403 = props.check403

    useEffect(() => {
        if (adminList === null && userlabAccessToken !== null){
            getAdmins(userlabAccessToken!)
                .then(res => {
                    setAdminList(res.data.sort((a,b)=>{return a.email > b.email ? 1  : -1}))
                })
                .catch(e => {
                    check403(e)
                    console.error(e)
                })
        }
    },
    [adminList, userlabAccessToken, check403])

    const onRemoveAdmin = (id: number) => {
        setRemovingAdmin(id)
        deleteAdmin(userlabAccessToken!, {id})
            .then(res => {
                setAdminList(adminList!.filter(a => a.id !== res.data.id))
                setRemovingAdmin(null)
            })
            .catch(e => console.error(e))
    }

    const onAddAdmin = (email: string)=>{
        if (userlabAccessToken !== null && adminList !== null){
            setLoadingAdmin(true)
            createAdmin(userlabAccessToken, {email})
                .then(res => {
                    const newAdmins = adminList.concat(res.data)
                    setAdminList(newAdmins.sort((a,b)=>{return a.email > b.email ? 1  : -1}))
                    setNewAdminOpen(false)
                    setLoadingAdmin(false)
                })
                .catch(e => {
                    setLoadingAdmin(false)
                    console.error(e)
                })
        }
    }

    let content = (
    <div id="manage-admins-spinner-container">
        <CircularProgress />
    </div>
    )
    if (adminList !== null ) {
        content = (
            <Fragment>
            <p id="manage-admins-explanation">
                Add people to this list to give them administrator privileges. This includes
                the ability to create and inspect test plans and to download responses. 
            </p>
            <div id="manage-admins-table">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">email</TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {adminList
                        .sort((a, b)=>{return a.email > b.email ? 1 : -1})
                        .map(a => {
                            return (
                            <TableRow
                            key={a.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {a.email}
                            </TableCell>
                            <TableCell align="right">
                                <LoadingButton
                                    variant="outlined" 
                                    color="error" 
                                    onClick={()=>{onRemoveAdmin(a.id)}}
                                    loading={removingAdmin === a.id}
                                >
                                    remove
                                </LoadingButton>
                            </TableCell>
                            </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
            <Button 
                variant="contained"
                startIcon={<AddIcon />}
                onClick={()=>{setNewAdminOpen(true)}}
                disableFocusRipple
            >New Admin</Button>
            </Fragment>
        )
    }
    return (
        <div id="manage-admins-page">
            <div id="manage-admins-content">
                <h1>Administrators</h1>
                {content}
            </div>
            <NewAdminModal 
                open={newAdminOpen}
                loading={loadingAdmin}
                onClose={()=>{setNewAdminOpen(false)}}
                onAdd={onAddAdmin}
            />
        </div>
    )
}


interface NewAdminModalProps {
    open: boolean
    loading: boolean
    onClose: ()=>void
    onAdd: (email: string)=>void
}
function NewAdminModal(props: NewAdminModalProps){
    const [email, setEmail] = useState("")
    const [formError, setFormError] = useState(false)
    const [addDisabled, setAddDisabled] = useState(true)
    const pattern = new RegExp(/.+@healthcatalyst\.com/)

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
        if (pattern.test(e.target.value) && addDisabled){
            setAddDisabled(false)
        } else if (!pattern.test(e.target.value) && !addDisabled) {
            setAddDisabled(true)
        }
        if (formError && pattern.test(e.target.value)) {
            setFormError(false)
        }
    }

    const onAddAdmin  = ()=>{
        if (pattern.test(email)){
            props.onAdd(email)
        } else {
            setFormError(true)
        }
    }

    const onClose = () => {
        setFormError(false)
        setEmail("")
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={onClose}>
            <DiaglogTitle>New Admin</DiaglogTitle>
            <DialogContent>
                <TextField
                    variant="standard"
                    error={formError}
                    helperText={formError ? "Email must end with '@healthcatalyst.com.'" : ""}
                    onChange={onInputChange}
                    style={{width: 300}}
                    autoFocus
                    inputProps={{
                        onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === "Enter"){
                                onAddAdmin()
                            }
                        }
                    }}
                    
                />
                <DialogContentText>
                    Please enter the email address of the new administrator. <br/>
                    Administrators must have an "@healthcatalyst.com" email address.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={onClose}>cancel</Button>
                <LoadingButton 
                    variant="contained" 
                    color="success" 
                    onClick={onAddAdmin}
                    loading={props.loading}
                    disabled={addDisabled}
                >
                    add  admin
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
