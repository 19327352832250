import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Switch, Route} from 'react-router-dom'

import './App.css';
import Login from './components/user/login/login'
import UserPage from './components/user/userPage/userPage'
import AdminPage from './components/admin/main/adminMain'
import DemoResultView from './components/user/demo-result/demoResult'
import {parseQueryParams} from './queryParamParser'
import { selectUserEmail, setEmail } from './components/user/login/userSlice'
import { setOrigin, getAuthToken } from './components/user/login/HASInfoSlice'
import {
  setTestplanId, 
  setStimulusBundleId,
  setDataCollectionEventId,
  setQueryParamsParsed, 
} from './components/user/userPage/testSlice'
import hcLogo from './components/user/login/icon/HCLogo.png'


interface AppProps {}
export default function App(props: AppProps){
  const userEmail = useSelector(selectUserEmail)
  const dispatch = useDispatch()

  const onLogin = (userEmail: string) => {
    dispatch(setEmail(userEmail))
  }

  const onLogout = () => {
    dispatch(setEmail(null))
  }

  useEffect(() => {
    // Initialize state that depends on the query params
    const queryParams = parseQueryParams()
    if (queryParams.email !== null){
      dispatch(setEmail(queryParams.email))
    }
    if (queryParams.origin !== null){
      dispatch(setOrigin(queryParams.origin))
      // Since they were sent from HAS, if there is also an email then
      // go ask for an auth token
      if (queryParams.email !== null) {
        dispatch(getAuthToken(queryParams.email))
      }
    }
    if (queryParams.testplanId !== null){
      dispatch(setTestplanId(queryParams.testplanId))
    }
    if (queryParams.dataCollectionEvent !== null){
      dispatch(setDataCollectionEventId(queryParams.dataCollectionEvent))
    }
    if (queryParams.stimulusBundleId !== null){
      dispatch(setStimulusBundleId(queryParams.stimulusBundleId))
    }
    dispatch(setQueryParamsParsed(true))
  // eslint-disable-next-line
  }, [])

  return  (
    <Switch>
      <Route path="/admin">
        <AdminPage routeBase="/admin" />
      </Route>
      <Route path={"/results"}>
        <DemoResultView />
      </Route>
      <Route path={`/missing-url`}>
        <div id="invalid-url">
          <img id="hc-logo" src={hcLogo} alt="product icon"/>
          <h1>Please ask your moderator for the URL</h1>
        </div>
      </Route>
      <Route path="/">
      {  
      userEmail ? 
      <UserPage 
        userEmail={userEmail}
        onLogout={onLogout}
      />
      :
      <Login onLogin={onLogin} />
      }
      </Route>
    </Switch>
  )
  
}
